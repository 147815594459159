import React from "react";
import { LoginStyle } from "./_style";

import LoginForm from "./LoginSMS/LoginForm";

function LoginSMS() {
    return (
        <LoginStyle.BackGround id="loginBG" className="">
            <LoginStyle.Wrap>
                {/* 로그인 폼 */}
                <LoginStyle.Box>
                    <LoginStyle.Inner>
                        <LoginStyle.Logo></LoginStyle.Logo>
                        {/* CHECK: 통합 컴포넌트  */}
                        <LoginForm isForLogin />

                        <LoginStyle.Copyright>Copyright © Movements,Inc. All rights reserved.</LoginStyle.Copyright>
                    </LoginStyle.Inner>
                </LoginStyle.Box>
                {/* 하단 로고 */}
                <LoginStyle.FooterLogo></LoginStyle.FooterLogo>
            </LoginStyle.Wrap>
        </LoginStyle.BackGround>
    );
}

export default LoginSMS;
