import React from "react";
import { PopupProps } from "./_interface";
import styled from "styled-components";
import { PopupStyle, SlidingStyle } from "./_style";

// 팝업 - sliding
function PopupSliding({ width = "408px", height, src, title = "스마트핀 정보", close, isOpen, children }: PopupProps) {
    return (
        <>
            {/* <SlidingStyle.Dimmed className={isOpen && "isOpen"}> </SlidingStyle.Dimmed> */}
            <SlidingStyle.Wrap width={width} height={height} className={isOpen && "isOpen"}>
                <SlidingStyle.Head>
                    <PopupStyle.Title src={src}>{title}</PopupStyle.Title>
                    <PopupStyle.Close type={"button"} onClick={close}>
                        <span className={"hidden"}>닫기</span>
                    </PopupStyle.Close>
                </SlidingStyle.Head>

                <SlidingStyle.Cont>{children && children}</SlidingStyle.Cont>
            </SlidingStyle.Wrap>
        </>
    );
}

export default PopupSliding;
