import styled from "styled-components";
import IcoEmpty from "assets/images/common/ico-empty.svg";

type EmptyTableProps = {
    width?: string;
    height?: string;
    nullText?: string;
    borderNone?: boolean;
};

export const EmptyTableStyle = {
    Wrap: styled.div<EmptyTableProps>`
        width: ${(p) => (p.width ? p.width : "100%")};
        height: ${(p) => (p.height ? p.height : "100%")};
        display: flex;
        justify-content: center;
        border: 1px solid #ebebeb;
        border-radius: 24px;
        box-sizing: border-box;
        &.borderNone {
            border: none;
        }
    `,
    Cont: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .ico {
            width: 32px;
            height: 32px;
            background: url(${IcoEmpty}) no-repeat center;
        }
    `,
};

// 테이블에 데이터가 없을때!
function EmptyTable({ width, height, nullText = "내역이 없습니다.", borderNone }: EmptyTableProps) {
    return (
        <EmptyTableStyle.Wrap width={width} height={height} className={`${borderNone && "borderNone"}`}>
            <EmptyTableStyle.Cont>
                <div className="ico"></div>
                <p>{nullText}</p>
            </EmptyTableStyle.Cont>
        </EmptyTableStyle.Wrap>
    );
}

export default EmptyTable;
