import React from "react";
import ReactDOM from "react-dom/client";
import App, { mainRoute } from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useNavigate } from "react-router-dom";
//recoil
import { RecoilRoot } from "recoil";
//react-query
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import PopupJs from "popup/popupjs/PopupJs";
import { removeUserData } from "service/api/common";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            /* staleTime ? "fresh 유지 시간(ms)" 
               refetch 조건이어도 refetch 안일어남 
               (Infinity : 서버 재시작때만 refetch / 0 : 기본설정 ) */
            // staleTime: 3 * 60 * 1000, // 5분 이후, refetch 조건이 오면 요청.
            staleTime: 0, // 5분 이후, refetch 조건이 오면 요청.
            // cacheTime: 0, // unMount 이후 쿼리 inactive 시간체크, GC 로 넘어가는 시간 (캐시에서 삭제)
            refetchOnMount: true, // 새로운 화면(컴포넌트)일때 시도
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: 0,
            onError(err: any) {
                console.log(err?.response?.data?.status);
                console.log(err?.response?.data?.error);
                if (err?.response?.data?.status === 401 && err?.response?.data?.error === "Unauthorized") {
                    alert("세션이 만료되었습니다.");
                    removeUserData();
                    window.location.href = mainRoute || "/";
                }
            },
        },
    },
});

//public/index.html - <title>
if (process.env.REACT_APP_SERVER === "운영") {
    document.title = "SP-작업자";
} else {
    document.title = "SP-작업자(test)";
}

root.render(
    //   <React.StrictMode>
    <RecoilRoot>
        <QueryClientProvider client={queryClient}>
            {/* react-query Devtools */}
            {/* <ReactQueryDevtools initialIsOpen={true} /> */}
            {/* 라우터 */}
            <BrowserRouter>
                <PopupJs />
                <App />
            </BrowserRouter>
        </QueryClientProvider>
    </RecoilRoot>,
    //   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
