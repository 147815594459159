import styled from "styled-components";
import { LabelFormProps } from "./_interface";
import IcoSearch from "assets/images/common/ico-search.svg";
import IcoInfo from "assets/images/common/ico-info.svg";
import IcoBuilding from "assets/images/common/ico-building.svg";

export const LabelForm = {
    Wrap: styled.div<LabelFormProps>`
        display: flex;
        flex-direction: ${(p) => (p.labelPos === "top" ? "column" : "row")};
        align-items: ${(p) => (p.align === "top" ? "flex-start" : "center")};
        gap: ${(p) => (p.gap ? p.gap + "px" : 0)};
    `,
    Label: styled.label`
        min-width: 85px;

        font-size: 1.6rem;
        font-weight: 400;
        color: #645349;
    `,
};

export const SearchFilterForm = {
    Wrap: styled.div`
        display: flex;
        align-items: center;
        margin-bottom: 32px;
    `,
    Search: styled.button`
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background: url(${IcoSearch}) no-repeat center;
    `,
    Bar: styled.span`
        width: 1px;
        height: 40px;
        background: #e6e6e6;
        margin: 0 20px;
    `,
};

export const AreaSelectStyle = {
    Wrap: styled.div`
        align-items: center;
        position: relative;
        height: 48px;
    `,
    InfoBtn: styled.div`
        width: 96px;
        height: 32px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        position: absolute;
        top: calc(50% - 16px);
        right: 52px;
        background: #fdf4bd;
        color: #645349;
        font-weight: 300;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            background: #ffe576;
        }
        &::before {
            content: "";
            width: 20px;
            height: 20px;
            background: url(${IcoInfo}) no-repeat center;
        }
    `,
    // 현장 정보 팝업 영역
    InfoWrap: styled.div`
        position: absolute;
        top: 100%;
        right: 52px;
        margin: 6px auto 0;
        z-index: 99;
        width: 310px;
        height: 159px;
        background-color: #fff;
        box-shadow: 0 0 6px 0 rgba(100, 83, 73, 0.16);
        border-radius: 12px;
        padding: 10px;
        box-sizing: border-box;
    `,
    InfoInner: styled.div`
        height: 100%;
        border: solid 1px #ebebeb;
        border-radius: 8px;
        display: flex;

        overflow: auto;
    `,
    TitleWrap: styled.div`
        width: 82px;
        height: 100%;
        background-color: #eff2f6;
        border: 1px solid #ebebeb;
        border-radius: 8px;
        padding: 16px 0 0 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        p {
            font-weight: 300;
            line-height: 17px;
            &:first-child {
                height: 40px;
            }
        }
    `,
    ContWrap: styled.div`
        width: calc(100% - 82px);
        padding: 16px 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        p {
            word-break: break-all;
            font-weight: 400;
            line-height: 17px;
            &:first-child {
                height: 40px;
            }
        }
    `,
};

// 현장 표시
export const AreaTagStyle = {
    Wrap: styled.div`
        width: 280px;
        height: 48px;
        border: 1px solid #e5e5ec;
        box-sizing: border-box;
        border-radius: 24px;
        overflow: hidden;

        display: flex;
        align-items: center;
        gap: 20px;

        .title {
            width: 97px;
            height: 100%;
            flex-shrink: 0;
            background-color: #645349;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            border-radius: 0 0 24px 0;
            font-size: 1.6rem;
            font-weight: 400;
            .ico {
                width: 24px;
                height: 24px;
                background: url(${IcoBuilding}) no-repeat center;
            }
        }
        .content {
            cursor: pointer;
            font-size: 1.6rem;
            font-weight: 400;
        }
    `,
};
