import React, { useEffect, useState, useCallback, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import Checkbox from "components/atoms/Checkbox";
// ico
import IcoMarkerA from "assets/images/kakaoMap/ico-marker-active.svg";
import IcoMarkerMap from "assets/images/kakaoMap/ico-marker-map.svg";

import IcoMarker from "assets/images/kakaoMap/ico-marker.svg";

import { FunctionStyle } from "./_style";
import { CoordDataProps, MarkerStateProps, CoordListProps } from "./_interface";
import { isEmpty } from "lodash";

interface kakaoProps {
    coordData?: CoordDataProps;
    selectedId?: string | number;
    openInfoPopup: (id?: string | number) => void;
}

declare global {
    interface Window {
        kakao: any;
    }
}

// 임시 데이터
export const myData: any = [
    {
        smartPinId: "1",
        title: "콜드스퀘어",
        lat: 37.62197524055062,
        lon: 127.16017523675508,
    },
    {
        smartPinId: "2",
        title: "하남돼지집",
        lat: 37.620842424005616,
        lon: 127.1583774403176,
    },
    {
        smartPinId: "3",
        title: "수유리우동",
        lat: 37.624915253753194,
        lon: 127.15122688059974,
    },
    {
        smartPinId: "4",
        title: "맛닭꼬",
        lat: 37.62456273069659,
        lon: 127.15211256646381,
    },
];

const { kakao } = window;

function KakaoMap(props: kakaoProps): JSX.Element {
    const { openInfoPopup, selectedId, coordData } = props;

    let prevMarker: any = null; // 이전 선택된 마커 객체

    const mapref = useRef(null); // 지도 올릴 Dom 객체 접근
    const [myMap, setMyMap] = useState(null); // kakao map 저장소
    const [markers, setMarkers] = useState<object[]>([]); // 마커 저장소 - 1

    useEffect(() => {
        // 선택된 마커 아이디 없을때
        if (!selectedId) {
            // 마커 이미지 초기화
            markers.forEach((marker: any) => {
                marker.setImage(marker.normalImage);
                marker.setZIndex(0);
            });
        }
        // selectedId 바뀔때
        if (selectedId) {
            markers.forEach((marker: any) => {
                if (marker.smartPinId === selectedId) {
                    marker.setImage(marker.activeImage);
                    marker.setZIndex(3);
                } else {
                    marker.setImage(marker.normalImage);
                    marker.setZIndex(0);
                }
            });
        }
    }, [selectedId, markers]);

    // (기존) 마커 표시하기 또는 지우기 (표시 : map, 지우기: null)
    const removeMarker = (map?: object | null) => {
        markers?.map((marker: any) => marker.setMap(map));
    };

    // 현장 변경시 마커 지우기
    useEffect(() => {
        removeMarker(null); // 마커 지우기(null)
    }, [coordData?.siteId]);

    // 기본 마커 올리기
    const markerOverlay = useCallback(
        (map: object, data: CoordListProps[]) => {
            // 마커 올리기
            if (data) {
                //  removeMarker(null, type); // 기존 마커 지우기

                const normalIcon = IcoMarkerMap;
                const size = 16;

                // 마커 이미지 - normal
                let icon = new kakao.maps.MarkerImage(normalIcon, new kakao.maps.Size(size, size), {
                    offset: new kakao.maps.Point(size / 2, size / 2),
                });
                // 마커 이미지 - active
                let iconActive = new kakao.maps.MarkerImage(IcoMarkerA, new kakao.maps.Size(35, 45), {
                    offset: new kakao.maps.Point(16, 35),
                });

                // 데이터 반복문 마커 올리기
                data?.map((el) => {
                    // 마커를 생성
                    let marker = new kakao.maps.Marker({
                        map: map, // 지도
                        position: new kakao.maps.LatLng(el.lat, el.lon), // 위치 객체
                        title: `PIN ID : ${el.smartPinId}`, // hover시 나타날 title
                        clickable: true, // 마커를 클릭할때, 지도 클릭이벤트 막기
                        image: icon,
                        zIndex: 0,
                    });

                    // 마커 내에 커스텀 데이터 추가
                    marker.smartPinId = el.smartPinId; // 고유 ID
                    marker.normalImage = icon; // 기본 마커
                    marker.activeImage = iconActive; // Active 마커

                    // 마커 클릭 이벤트 연결
                    kakao.maps.event.addListener(marker, "click", function () {
                        // 이전 마커 있을 때
                        // if (prevMarker) {
                        //     prevMarker.setImage(prevMarker.normalImage); // 마커 이미지 normal
                        // }

                        openInfoPopup(marker.smartPinId); // 상세창 오픈 이벤트

                        // marker.setImage(marker.activeImage); // 마커 이미지 active
                        // prevMarker = marker; // 이전 마커에 클릭한 마커 저장
                    });

                    // 생성된 마커를 배열에 추가합니다
                    setMarkers((prev) => [...prev, marker]);
                });

                // 클릭한 위도, 경도 정보를 가져옵니다
                kakao.maps.event.addListener(map, "click", function (e: any) {
                    var latlng = e.latLng;
                    console.log(latlng);
                });
            }
        },
        [openInfoPopup, coordData],
    );

    // ** 초기 지도 한번 그리기 - [deps] 추가 금지
    useLayoutEffect(() => {
        try {
            kakao.maps.load(() => {
                console.log("map load...");
                const options = {
                    center: new kakao.maps.LatLng(36.8, 127),
                    // level 추가 X
                };
                const kakaoMap = new kakao.maps.Map(mapref.current, options);

                setMyMap(kakaoMap);
            });
        } catch (e) {
            console.log(e);
        }
    }, []);

    // 지도 중심 변경 실행 함수
    const changeMapCenter = useCallback(
        (center: { lat: number; lon: number; level?: number }) => {
            const { lat = 0, lon = 0, level = 13 } = center || {};
            if (myMap) {
                myMap.setCenter(new kakao.maps.LatLng(lat, lon)); // 위치 변경
                myMap.setLevel(level); // level 변경
            }
        },
        [myMap],
    );

    // 지도 중심 변경
    useLayoutEffect(() => {
        // 현재 현장 유무
        if (coordData?.siteId) {
            let center;
            // 현장 중심값 있을때
            if (!isEmpty(coordData)) {
                if (coordData?.centroidLat && coordData?.centroidLon) {
                    center = {
                        lat: coordData?.centroidLat,
                        lon: coordData?.centroidLon,
                        level: 3,
                    };
                } else {
                    center = { lat: 36.8, lon: 127.7, level: 13 }; // 기본 중심값 (대한민국)
                }
            }
            changeMapCenter(center); // 지도 중심 변경 실행 함수
        }
    }, [coordData, changeMapCenter]);

    // 지도 마커 올리기 - 시공 데이터만!
    useLayoutEffect(() => {
        if (myMap) {
            // 전체 데이터 -> 시공 데이터 분리
            let totalData = coordData?.coordinateList; // 전체 데이터 (설계)
            let constructData = totalData?.filter((ele) => ele.isCompleted === 1); // (필터)시공완료 데이터

            markerOverlay(myMap, constructData);
        }
    }, [myMap, markerOverlay, coordData?.coordinateList]);

    //  // 현장 영역 그리기 (Polygon)
    //  useEffect(() => {
    //      if (myMap) {
    //          let positions = TransToPolyPath();
    //          console.log("Polygon", positions);

    //          if (positions) {
    //              let polygon = GetPolygon(positions);

    //              // 지도에 다각형을 표시합니다
    //              polygon?.setMap(myMap);
    //          }
    //      }
    //  }, [myMap]);

    return (
        <>
            <FunctionStyle.Wrap>
                <FunctionStyle.LegendWrap ico1={IcoMarker}>
                    <li>시공</li>
                </FunctionStyle.LegendWrap>
            </FunctionStyle.Wrap>

            {/* 카카오 지도 */}
            <div id="kakaoMap" style={{ width: "100%", height: "100%" }} ref={mapref}></div>
        </>
    );
}

export default React.memo(KakaoMap);

// lat, lon 배열을 받아서, kakao 지도에 적용할 폴리곤 좌표 배열로 변환
export function TransToPolyPath(data?: any) {
    try {
        // 임시 데이터
        const myData = [
            { lat: 33.45133510810506, lon: 126.57159381623066 },
            { lat: 33.44955812811862, lon: 126.5713551811832 },
            { lat: 33.449986291544086, lon: 126.57263296172184 },
            { lat: 33.450682513554554, lon: 126.57321034054742 },
            { lat: 33.451346760004206, lon: 126.57235740081413 },
        ];

        let polygonPath = myData.map((ele) => new kakao.maps.LatLng(ele.lat, ele.lon)); // map 객체 생성 이후에 가능

        return polygonPath;
    } catch {}
}

// kakao 지도에 적용할 폴리곤 객체 설정
export function GetPolygon(polygonPath: any[]) {
    try {
        // 지도에 표시할 다각형을 생성합니다
        var polygon = new kakao.maps.Polygon({
            path: polygonPath, // 그려질 다각형의 좌표 배열입니다
            strokeWeight: 3, // 선의 두께입니다
            strokeColor: "#39DE2A", // 선의 색깔입니다
            strokeOpacity: 0.8, // 선의 불투명도 입니다
            strokeStyle: "longdash", // 선의 스타일입니다
            fillColor: "#A2FF99", // 채우기 색깔입니다
            fillOpacity: 0.7, // 채우기 불투명도 입니다
        });

        return polygon;
    } catch {}
}
