import React, { useEffect, useState } from "react";
import PopupTwoBtn from "popup/PopupTwoBtn";
import { mainRoute } from "App";
import { removeUserData } from "service/api/common";
import { useNavigate } from "react-router-dom";

function TimeOut() {
    const min = process.env.NODE_ENV === "development" ? 100 : 5; // 분
    const limitTimer = 60 * min;

    const navigate = useNavigate();
    const [loginTimer, setLoginTimer] = useState(limitTimer);
    const [onShow, setOnShow] = useState(false);

    //타이머 수치 감지
    useEffect(() => {
        const interval = setInterval(() => {
            //타임 체크
            // console.log(loginTimer);

            // 로그아웃 알림 시간 기준 - 59초
            if (loginTimer === 59) {
                //경고 팝업 오픈
                setOnShow(true);
            }
            if (loginTimer < 1) {
                //로그 아웃 처리
                logoutAction();
                return;
            }

            setLoginTimer((prev) => prev - 1);
        }, 1000); // 1초마다 실행

        return () => clearInterval(interval);
    }, [loginTimer]);

    // 마우스 이벤트 연결
    useEffect(() => {
        const mouseMoveEvent = () => {
            // onShow 값이 false 일때만(알림팝업이 없을때만) 초기화 하도록 조건 추가하기(테스트후)
            if (!onShow) {
                setLoginTimer(limitTimer);
            }
        };
        window.addEventListener("mousemove", mouseMoveEvent);
        return () => window.removeEventListener("mousemove", mouseMoveEvent);
    }, [loginTimer, onShow]);

    // 로그아웃
    const logoutAction = () => {
        removeUserData();
        navigate(mainRoute);
        window.location.reload();
    };

    const resetTimer = () => {
        setLoginTimer(limitTimer); // 타이머 리셋
        setOnShow(false); // 팝업 닫기
    };

    return (
        <>
            {onShow && (
                <>
                    <PopupTwoBtn width={"300px"} height={"240px"} title="자동 로그아웃 안내" leftText="로그아웃" leftFunc={logoutAction} rightText="시간연장(5분)" rightFunc={resetTimer}>
                        <div className="flex dir_column jc_center ai_center" style={{ height: "100%" }}>
                            <h1 style={{ fontSize: "1.6rem" }}>
                                남은 시간 : <span style={{ fontSize: "1.6rem", color: "#0070d1" }}>{loginTimer}</span> 초
                            </h1>
                            <h1 style={{ fontSize: "1.6rem" }}>자동 로그아웃 됩니다.</h1>
                        </div>
                    </PopupTwoBtn>
                </>
            )}
        </>
    );
}

export default TimeOut;
