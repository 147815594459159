import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import KakaoMap from "components/organisms/KakaoMap";
import { ContStyle } from "./_style";
import { MapFrameStyle } from "./PinMap/_style";
//icon
import icoTitle01 from "assets/images/common/Menu/ico-title-01.svg";
import IcoPinList from "assets/images/pinMap/ico-pinList.svg";
//service
import useQueryState from "hooks/my-react-query/useQueryState";
import { queryKeys } from "hooks/my-react-query/QueryKeys";
import { getPinCoords } from "service/api/common";
import { useRecoilValue } from "recoil";
import { siteState } from "recoil/globalState";
// component
import PopupSliding from "popup/PopupSliding";
import PinInfo from "./PinMap/PinInfo";
import PinList from "./PinMap/PinList";
import { CoordDataProps } from "components/organisms/_interface";
import MyButton from "components/atoms/MyButton";
import AreaTag from "components/molcures/AreaTag";
import { useEscapeKey } from "util/useEscapeKey";
import Loading from "components/atoms/Loading";

// 시공 데이터 페이지 - 작업자용
function PinMap() {
    const siteInfo = useRecoilValue(siteState);

    const [loading, setLoading] = useState(false);

    const [selectedId, setSelectedId] = useState(null);
    const [isOpen, setIsOpen] = useState(false); //상세 팝업
    const [isOpenPinList, setIsOpenPinList] = useState(false); //스마트핀 목록 팝업

    // [React-query]
    const { data: coordData, refetch } = useQueryState<CoordDataProps>(queryKeys.pinCoordById(siteInfo.siteId), () => getPinCoords({ siteId: siteInfo.siteId }), {
        enabled: !!siteInfo.siteId,
    }); // 스마트핀 좌표 조회(센터,마커)

    // 선택된 마커(스마트핀id) 있을 때 -> 상세 팝업 오픈
    useEffect(() => {
        if (selectedId) {
            setIsOpen(true);
        }
    }, [selectedId]);

    // 마커 선택 - 상세창 오픈
    const openPopup = useCallback((id?: any) => {
        setIsOpen(true);
        setSelectedId(id);
        console.log(id);
    }, []);

    // 상세 팝업 - 닫기 이벤트
    const closePopup = useCallback(() => {
        setIsOpen(false);
        setSelectedId(null);
    }, []);

    useEscapeKey(closePopup); // 상세 팝업 esc 닫기
    useEscapeKey(() => setIsOpenPinList(false)); // 스마트핀 목록 팝업 esc 닫기

    return (
        <>
            <ContStyle.Wrap>
                {loading && <Loading />}
                <ContStyle.Main>
                    <ContStyle.Head>
                        <ContStyle.Title ico={icoTitle01}>{"시공 데이터"}</ContStyle.Title>
                        {/* <button onClick={() => setLoading(!loading)}>loading</button> */}
                        {/* 현장 표시 */}
                        <AreaTag />
                    </ContStyle.Head>
                    <ContStyle.Cont>
                        {/* 지도 영역 */}
                        <MapFrameStyle.Wrap>
                            <MapFrameStyle.Inner>
                                {/* Open Layers 지도 */}
                                {/* <OlMap coordData={coordData} selectedId={selectedId} openInfoPopup={openPopup} /> */}
                                {/* 카카오 지도 */}
                                <KakaoMap openInfoPopup={openPopup} selectedId={selectedId} coordData={coordData} />
                                {/* 스마트핀 목록 버튼 */}
                                <MyButton colorType="yellow" text="스마트핀 목록" width={150} height={48} color="#000" fontSize={16} leftIcon={IcoPinList} style={{ position: "absolute", top: 10, right: 10, zIndex: "9" }} onClick={() => setIsOpenPinList(true)} />
                            </MapFrameStyle.Inner>
                        </MapFrameStyle.Wrap>
                    </ContStyle.Cont>

                    {/* 스마트핀 목록 팝업 - sliding - 오른쪽 */}
                    <PopupSliding isOpen={isOpenPinList} close={() => setIsOpenPinList(false)} title="스마트핀 목록">
                        {isOpenPinList && <PinList setSelectedId={setSelectedId} smartPinId={selectedId} />}
                    </PopupSliding>

                    {/* 상세_팝업 - sliding - 오른쪽 */}
                    <PopupSliding isOpen={isOpen} close={closePopup}>
                        {isOpen && <PinInfo smartPinId={selectedId} />}
                    </PopupSliding>
                </ContStyle.Main>
            </ContStyle.Wrap>
        </>
    );
}

export default PinMap;
