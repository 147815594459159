import React, { useEffect, useRef, useState, useCallback, useLayoutEffect } from "react";
import { SelectStyle } from "./_style";
import { SelectProps } from "./_interface";
import { useOutsideClick } from "util/useOutsideClick";

const Select = ({ width, height, maxHeight, radius, option = [], onChange, marginRight, style, trigger, value, disabled, name, ico, icoA, placeholder, fontSize }: SelectProps) => {
    const comboRef = useRef<HTMLDivElement>(null); //셀렉트 박스 영역
    const [selected, setSelected] = useState<string | number | undefined>();
    // option[0]?.text // 현재 선택된 옵션의 텍스트 (default : 첫번째 항목 )
    const [toggle, setToggle] = useState(false); //셀렉트박스 on/off

    useOutsideClick(() => setToggle(false), comboRef); // 외부영역 클릭 이벤트 - 셀렉트 박스 닫기

    // 선택한 텍스트로 변경
    const onTextChange = (e: React.MouseEvent) => {
        const eventTarget = e.currentTarget as HTMLLIElement;
        setSelected(eventTarget.innerText);
    };

    //value 값이 있으면 초기값 변경
    useLayoutEffect(() => {
        if (value) {
            setSelected(value);
        }
    }, [value]);

    useEffect(() => {
        if (trigger) {
            setSelected(trigger.text);
        }
    }, [trigger]);

    const onToggle = () => {
        if (!disabled) {
            setToggle(!toggle);
        }
    };

    console.log(selected);

    return (
        <>
            <SelectStyle.Wrap ref={comboRef} width={width} marginRight={marginRight}>
                <SelectStyle.Selected ico={ico} icoA={icoA} style={style} width={width} height={height} radius={radius} fontSize={fontSize} onClick={onToggle} className={toggle ? "active" : disabled ? "disabled" : undefined}>
                    {selected ? <div className="value">{selected}</div> : <div className="placeholder">{placeholder}</div>}
                </SelectStyle.Selected>

                <SelectStyle.ListWrap className={"cScroll"} maxHeight={maxHeight} radius={radius}>
                    {/* TODO: li 대신 div 태그로 click 구현해보기 & ellipsis 적용하기 */}
                    <SelectStyle.List>
                        {option?.map(({ text, value }: any, index) => {
                            return (
                                <SelectStyle.Item
                                    fontSize={fontSize}
                                    className={text === selected ? "active" : undefined}
                                    key={index}
                                    aria-valuetext={value}
                                    onClick={(e) => {
                                        onChange?.(e, name);
                                        onTextChange(e);
                                        onToggle();
                                    }}
                                >
                                    {/* {text} */}
                                    <div className="value">{text}</div>
                                </SelectStyle.Item>
                            );
                        })}
                    </SelectStyle.List>
                </SelectStyle.ListWrap>
            </SelectStyle.Wrap>
        </>
    );
};

export default Select;
