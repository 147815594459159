import { AxiosResponse, AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { useQuery, QueryKey, UseQueryOptions, QueryFunction } from "react-query";

export default function useQueryState<T>(key: QueryKey[], fetchApi: QueryFunction<AxiosResponse<T, any>, QueryKey[]>, options?: Omit<UseQueryOptions<AxiosResponse<T, any>, AxiosError<unknown, any>, T, QueryKey[]>, "queryKey" | "queryFn">) {
    const { data, refetch } = useQuery<AxiosResponse<T>, AxiosError, T, QueryKey[]>(key, fetchApi, options);
    const [propData, setPropData] = useState<T>(null);

    // query 요청한 data -> 바로 Dom에 사용시 깜빡임 현상
    // ex) data.map((ele) => <div>{ele.id}</div>)
    // 개선책 _ useLayoutEffect
    useLayoutEffect(() => {
        if (data) {
            setPropData(data);
        }
    }, [data]);

    return { data: propData, refetch };
}

// 기본 react-query -> re-rendering
// useEffect(setState) -> re-rendering
