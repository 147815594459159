import React, { useState } from "react";
import { TocStyle } from "./_style";
import { TocDep1Props, TocDep2Props, TocProps } from "./_interface";
import { useNavigate } from "react-router-dom";
import { removeUserData } from "service/api/common";
import UserTag from "components/atoms/UserTag";
import usePopup from "popup/popupjs/hook/usePopup";
import PopupContact from "./Toc/PopupContact";
import PopupVersion from "./Toc/PopupVersion";
//
import IcoMail from "assets/images/common/Menu/ico-mail.svg";
import IcoMailA from "assets/images/common/Menu/ico-mail-active.svg";
import IcoInfo from "assets/images/common/Menu/ico-info.svg";
import IcoInfoA from "assets/images/common/Menu/ico-info-active.svg";

const Toc = ({ menu = [], onDep1Click, onDep2Click, hide, onToggle, mainRoute }: TocProps<TocDep1Props<TocDep2Props>>) => {
    const navigate = useNavigate();
    const [hoverKey, setHoverKey] = useState("");
    const [showVersion, setShowVersion] = useState(false);
    const [showCantact, setShowContact] = useState(false);
    const { addPopup, removeCurrentPopup } = usePopup();

    const contactUs = "문의하기";
    const versionInfo = "버전 정보";

    const logoutAction = () => {
        removeUserData();
        navigate(mainRoute);
        window.location.reload();
    };

    // console.log(hoverKey);
    return (
        <>
            {/* 문의하기 팝업 */}
            {showCantact && <PopupContact close={() => setShowContact(false)} />}
            {/* 버전 정보 팝업 */}
            {showVersion && <PopupVersion close={() => setShowVersion(false)} />}

            <TocStyle.Wrap className={hide ? "hide" : undefined}>
                {/* 토글 버튼 */}
                <TocStyle.Toggle className={hide ? "hide" : undefined} type={"button"} onClick={onToggle}></TocStyle.Toggle>
                {/* 로고 */}
                <TocStyle.Logo className={hide ? "hide" : undefined}></TocStyle.Logo>

                {/* 메뉴 버튼 모음 */}
                <TocStyle.List>
                    {menu.map(({ active, name, dep2, ico, icoActive }, index) => {
                        return (
                            <TocStyle.Item
                                key={index}
                                className={active && dep2 ? "active hasDep" : active && !dep2 ? "active" : !active && dep2 ? "hasDep" : undefined}
                                ico={ico}
                                icoActive={icoActive}
                                onMouseEnter={() => hide && setHoverKey(name)}
                                onMouseLeave={() => hide && setHoverKey("")}
                                onClick={() => onDep1Click(index)}
                            >
                                <div className="textArea">{!hide && <span>{name}</span>}</div>

                                {/* 툴팁 */}
                                <TooltipText hoverKey={hoverKey} show={name === hoverKey} />
                            </TocStyle.Item>
                        );
                    })}
                </TocStyle.List>

                {/* <div style={{ width: "100%", padding: "0 0 20px 12px", boxSizing: "border-box", borderBottom: "1px solid #393938", color: "#a0a5b1" }}>
                    {!hide && (
                        <>
                            <p style={{ marginBottom: "10px" }}>문의하기</p>
                            <p>movements.kr@daum.net</p>
                        </>
                    )}
                </div> */}

                {/* 하단 버튼 모음 */}
                <TocStyle.BottomList>
                    <TocStyle.BottomItem ico={IcoMail} icoActive={IcoMailA} className={hide && "hideV"} onMouseEnter={() => hide && setHoverKey(contactUs)} onMouseLeave={() => hide && setHoverKey("")} onClick={() => setShowContact(true)}>
                        {!hide && <div>{contactUs}</div>}
                        {/* 툴팁 */}
                        <TooltipText hoverKey={hoverKey} show={contactUs === hoverKey} />
                    </TocStyle.BottomItem>

                    <TocStyle.BottomItem ico={IcoInfo} icoActive={IcoInfoA} className={hide && "hideV"} onMouseEnter={() => hide && setHoverKey(versionInfo)} onMouseLeave={() => hide && setHoverKey("")} onClick={() => setShowVersion(true)}>
                        {!hide && <div>{versionInfo}</div>}
                        {/* 툴팁 */}
                        <TooltipText hoverKey={hoverKey} show={versionInfo === hoverKey} />
                    </TocStyle.BottomItem>
                </TocStyle.BottomList>

                {/* 로그 아웃 */}
                <TocStyle.Logout onMouseEnter={() => hide && setHoverKey("Log out")} onMouseLeave={() => hide && setHoverKey("")} onClick={logoutAction}>
                    <div className={` wrap ${hide && "center"}`}>{!hide && <span>Log out</span>}</div>
                    {/* 툴팁 */}
                    <TooltipText hoverKey={hoverKey} show={"Log out" === hoverKey} />
                </TocStyle.Logout>
            </TocStyle.Wrap>
        </>
    );
};

export default Toc;

// 툴팁 (메뉴 축소 버전 hover시)
export const TooltipText = ({ hoverKey, show }: { hoverKey: string; show: boolean }) => {
    return <TocStyle.Tooltip className={show && "show"}>{show && hoverKey}</TocStyle.Tooltip>;
};
