import React, { useState } from "react";
import { PinInfoStyle } from "./_style";
import PipeTag from "components/atoms/PipeTag";
import PopupLargeImg from "./PopupLargeImg";
//hook, service
import useQueryState from "hooks/my-react-query/useQueryState";
import { queryKeys } from "hooks/my-react-query/QueryKeys";
import { useRecoilValue } from "recoil";
import { siteState } from "recoil/globalState";
import { getPinDetail } from "service/api/common";
import { isEmpty } from "lodash";
import EmptyTable from "components/organisms/EmptyTable";
import NFCTag from "components/atoms/NFCTag";

type PinDetailProps = {
    smartPinId?: string;
    designX?: number;
    designY?: number;
    x?: number;
    y?: number;
    largeNfc?: number;
    lowNfc?: number;
    imgList?: string[];
    isSurveyed?: 0 | 1; // 동판 시공 여부
    isCompleted?: 0 | 1; // 시공 완료 여부
    completedDate?: string;
    pipeType?: number[];
    no?: number;
};

// const mainTarget = process.env.NODE_ENV === "development" ? process.env.REACT_APP_MAIN_TARGET_IMG_TEST : "";
const mainTarget = "";

// 상세 정보
function DetailCont({ smartPinId }: { smartPinId?: string }) {
    const siteInfo = useRecoilValue(siteState);

    const [isOpen, setIsOpen] = useState(false); // 이미지 크게 보기 팝업
    const [initImgNum, setInitImgNum] = useState(0);

    // [React-query]
    const { data, refetch } = useQueryState<PinDetailProps>(queryKeys.pinDetailById(smartPinId), () => getPinDetail({ siteId: siteInfo.siteId, smartPinId }), {
        enabled: !!smartPinId, // 핀 아이디
    }); // 스마트핀 상세 조회

    //  console.log("상세 정보/Pin ID:", smartPinId);
    //  console.log("상세 정보:", data);

    // 시공 사진 클릭
    const onClickImg = (index: number) => {
        setIsOpen(true);

        setInitImgNum(index); //시작 이미지 넘버
    };

    return (
        <>
            {/* 이미지 크게 보기 팝업  */}
            {isOpen && <PopupLargeImg mainTarget={mainTarget} imgList={data?.imgList} initNum={initImgNum} close={() => setIsOpen(false)} />}

            <PinInfoStyle.Detail>
                <ul className="noDrag">
                    <li>
                        <p>PIN ID</p>
                        <PinInfoStyle.SubCont>{data?.smartPinId}</PinInfoStyle.SubCont>
                    </li>
                    <li>
                        <p>NFC 타입</p>
                        <PinInfoStyle.SubCont>
                            {data?.isSurveyed === 0 && <NFCTag type="0" />}
                            {data?.isSurveyed === 1 && (
                                <div className="flex ai_center" style={{ gap: "8px", marginLeft: "auto" }}>
                                    <NFCTag type="1" /> {data?.largeNfc} <NFCTag type="2" /> {data?.lowNfc}
                                </div>
                            )}
                        </PinInfoStyle.SubCont>
                    </li>
                    <li>
                        <p>관 종류</p>
                        <PinInfoStyle.SubCont style={{ flexDirection: "row" }}>
                            {data?.pipeType?.map((ele) => (
                                <PipeTag key={"pipe" + ele} type={String(ele)} />
                            ))}
                        </PinInfoStyle.SubCont>
                    </li>

                    {data?.isCompleted === 1 && (
                        <>
                            <li>
                                <p>시공 위치</p>
                                <PinInfoStyle.SubCont>
                                    <span>X : {data?.x}</span>
                                    <span>Y : {data?.y}</span>
                                </PinInfoStyle.SubCont>
                            </li>
                            <li>
                                <p>시공 날짜</p>
                                <PinInfoStyle.SubCont>{data?.completedDate}</PinInfoStyle.SubCont>
                            </li>
                        </>
                    )}
                </ul>
                {/* 이미지 영역 */}
                {!isEmpty(data?.imgList) ? (
                    <PinInfoStyle.ImageWrap className="cScroll">
                        {data?.imgList?.map((img: any, index) => (
                            <div key={"img" + index} className="img" onClick={() => onClickImg(index)}>
                                <img src={`${mainTarget}${img?.imgUrl}`} alt={"img" + index} />
                            </div>
                        ))}
                    </PinInfoStyle.ImageWrap>
                ) : (
                    <div style={{ marginTop: "20px" }}>
                        <EmptyTable height="100px" nullText="이미지가 없습니다." />
                    </div>
                )}
            </PinInfoStyle.Detail>
        </>
    );
}

export default React.memo(DetailCont);
