import React from "react";
import { ContStyle, ArApplyStyle } from "./_style";
import styled from "styled-components";
//icon
import icoTitle02 from "assets/images/common/Menu/ico-title-02.svg";
import AreaTag from "components/molcures/AreaTag";
import ApplyList from "./ArApply/ApplyList";
import ApplyForm from "./ArApply/ApplyForm";

// AR 서비스 신청 페이지
function ArApply() {
    return (
        <ContStyle.Wrap>
            <ContStyle.Main>
                <ContStyle.Head>
                    <ContStyle.Title ico={icoTitle02}>{"AR 서비스 신청 및 확인"}</ContStyle.Title>
                    {/* 현장 선택 */}
                    <AreaTag />
                </ContStyle.Head>
                <ContStyle.Cont>
                    <ArApplyStyle.Wrap>
                        {/* 기존 신청 내역(유효한 신청) */}
                        <ArApplyStyle.Section className="gray">
                            <div className="tab">기존 신청 내역 (유효한 신청)</div>
                            <div className="cont">
                                <ApplyList />
                            </div>
                        </ArApplyStyle.Section>

                        <ArApplyStyle.Divider></ArApplyStyle.Divider>
                        {/* AR 서비스 신청 */}
                        <ArApplyStyle.Section>
                            <div className="tab">AR 서비스 신청</div>
                            <div className="cont" style={{ padding: "16px 20px" }}>
                                <ApplyForm />
                            </div>
                        </ArApplyStyle.Section>
                    </ArApplyStyle.Wrap>
                </ContStyle.Cont>
            </ContStyle.Main>
        </ContStyle.Wrap>
    );
}

export default ArApply;
