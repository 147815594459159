import React, { useMemo, useState } from "react";
import { PinInfoStyle } from "./_style";
import DetailCont from "./DetailCont"; // 상세 정보

// 스마트핀 정보 팝업 - children
// 상세정보 & AR사용이력 - 탭 전환
function PinInfo({ smartPinId }: { smartPinId?: string }) {
    const [tabMenu, setTabMenu] = useState([
        {
            name: "상세정보",
            active: true,
        },
    ]);

    // 탭버튼 클릭 이벤트
    const onClickTab = (name: string) => {
        setTabMenu((prev) => {
            let newArr = [...prev];
            newArr.map((ele) => (ele.name === name ? (ele.active = true) : (ele.active = false)));
            return newArr;
        });
    };

    // 활성화된 탭에 맞는 내용
    const SelectContent = useMemo(() => {
        const active = tabMenu.find((ele) => ele.active);
        const activeName = active?.name;

        if (activeName === "상세정보") {
            return <DetailCont smartPinId={smartPinId} />;
        }
    }, [tabMenu, smartPinId]);

    return (
        <PinInfoStyle.Wrap>
            {/* 탭 영역 */}
            <PinInfoStyle.Head>
                {tabMenu.map(({ name, active }, index) => (
                    <PinInfoStyle.TabBtn key={index + name} className={active ? "active" : ""} onClick={() => onClickTab(name)} style={{ cursor: "default" }}>
                        {name}
                    </PinInfoStyle.TabBtn>
                ))}
            </PinInfoStyle.Head>
            {/* 하단 border 내부 */}
            <PinInfoStyle.Cont className="cScroll">
                {/* 내용 영역 */}
                {SelectContent}
            </PinInfoStyle.Cont>
        </PinInfoStyle.Wrap>
    );
}

export default React.memo(PinInfo);
