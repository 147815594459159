import styled from "styled-components";
import IcoEdit from "assets/images/pinMap/ico-edit.svg";

// 지도 - 프레임 스타일
export const MapFrameStyle = {
    Wrap: styled.div`
        width: 100%;
        height: 100%;
        padding: 12px;
        box-sizing: border-box;
        background-color: #f2f7fa;
        border-radius: 24px;
    `,
    Inner: styled.div`
        width: 100%;
        height: 100%;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
    `,
};

// 스마트핀 상세 팝업 children
export const PinInfoStyle = {
    Wrap: styled.div`
        padding: 6px 24px 0 24px; // 양쪽 padding
        box-sizing: border-box;
        height: 100%;
    `,
    Head: styled.div`
        display: flex;
    `,
    TabBtn: styled.div`
        width: 100px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px 10px 0 0;
        background-color: #b8b9be;
        color: #fff;
        font-weight: 300;
        border: 2px solid transparent;
        border-bottom: 0;
        cursor: pointer;
        &.active {
            color: #1b1b1b;

            background-color: #fdf4bd;
            border-color: #ffe500;
        }
    `,
    Cont: styled.div`
        width: 100%;
        height: calc(100% - 42px);
        border: 2px solid #ffe500;
        border-radius: 0 8px 8px 8px;
        padding: 24px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        & > * {
            flex-shrink: 0;
        }
        overflow-y: auto;
        overflow-x: hidden;
    `,
    //  상세 정보
    Detail: styled.div`
        /* height: calc(100% - 100px); */
        /* overflow: auto; */
        /* padding: 24px;
        box-sizing: border-box; */
        ul {
            display: flex;
            flex-direction: column;
            gap: 20px;
            li {
                width: 100%;
                display: flex;
                justify-content: space-between;
                p {
                    width: 170px;
                    font-weight: 300;
                    color: #645349;
                }
            }
        }
    `,
    SubCont: styled.div`
        width: fit-content;
        font-weight: 300;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-end;
        gap: 6px;
        span {
            font-weight: 300;
        }
        &.tag {
            width: 22px;
            height: 22px;
            border: 1px solid #e5e5ec;
            box-sizing: border-box;
            border-radius: 2px;
            text-align: center;
            font-size: 1.1rem;
            justify-content: center;
            color: #4e4e4f;
            &.Y {
                border-color: #000;
                background-color: #ffe500;
                margin: 0;
            }
        }
    `,
    ImageWrap: styled.div`
        margin-top: 20px;
        width: 100%;
        overflow-x: auto;
        display: flex;
        gap: 6px;
        padding-bottom: 10px;
        div.img {
            flex-shrink: 0;
            width: 88px;
            height: 88px;
            border-radius: 8px;
            cursor: pointer;

            display: flex;

            background-color: #c3c3c3; //이미지 없음
            border: 1px solid #c3c3c3;
            background-color: #fff;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
        }
    `,
    // 수정 버튼
    Divider: styled.div`
        width: 100%;
        height: 1px;
        border-top: dashed 1px #645349;
        margin-top: auto;
    `,
    FooterBtn: styled.div`
        width: 100%;
        height: 48px;
        margin-top: 20px;
        border: 1px solid #645349;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
        transition: all 0.3s;
        &::before {
            content: "";
            width: 24px;
            height: 24px;
            background: url(${IcoEdit}) no-repeat center;
        }
        &:hover {
            background-color: #f0ecea;
        }
    `,
};

// 스마트핀 목록
export const PinListStyle = {
    Wrap: styled.div`
        height: 100%;
    `,
    SearchWrap: styled.div`
        padding: 0 20px 16px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #dedede;
        .totalNum {
            font-size: 1.6rem;
            font-weight: 300;
            span {
                font-size: 1.6rem;
                font-weight: 300;
                margin-left: 8px;
                color: #645349;
            }
        }
    `,
    NFCWrap: styled.div`
        width: 100%;
        display: flex;
    `,
    NFCType: styled.div`
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        &:nth-child(1) {
            border-right: 1px solid #e6e6e6;
        }
    `,
};
