import React, { useEffect, useRef, useState } from "react";
import { InputStyle } from "./_style";
import { DatePickerProps } from "./_interface";
import DatePicker, { DateObject } from "react-multi-date-picker";
import moment from "moment";

const RangePicker = ({ ref, width, height, marginRight, id, name, component, onChange = () => {}, onCustomChange, reset, value }: DatePickerProps) => {
    const calendarRef = useRef(null);
    const pickerType = "button";

    const onChangePicker = (selectedDates: DateObject | DateObject[]) => {
        if (Array.isArray(selectedDates)) {
            // YYYY-MM-DD로 변환
            const [startDate, endDate] = selectedDates.map((ele) => moment((ele as DateObject).toDate()).format("YYYY-MM-DD"));

            onCustomChange && onCustomChange(startDate, endDate, name); // onchange 이벤트
        }
    };

    // placeholder Dom 클릭 이벤트
    const onClickPlaceHolder = (e: any) => {
        const rmdpInput = e?.target?.previousElementSibling?.firstChild; //  형제관계에 있는 DatePicker의 input을 활성화(focus)
        //   console.log(rmdpInput);
        rmdpInput?.focus();
        //   calendarRef.current.openCalendar(); // input focus 스타일이 적용되지 않음.
    };

    return (
        <InputStyle.Wrap width={width} height={height} marginRight={marginRight}>
            <DatePicker
                ref={calendarRef}
                type={pickerType}
                containerClassName={"rangePicker"}
                value={value} // 초기값 없을때 필요x
                onChange={onChangePicker}
                range={true}
                calendarPosition={"bottom-end"}
                dateSeparator={" - "}
                monthYearSeparator="."
                numberOfMonths={1}
                placeholder={"날짜 선택"}
                months={["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]}
                weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                format={"YYYY.MM.DD"}
                minDate={new DateObject()}
            ></DatePicker>
            {/* type이 button인 경우 placeholder 적용 안됨 */}
            {pickerType === "button" && !value[0] && (
                <div className="placeHolder" onClick={(e) => onClickPlaceHolder(e)}>
                    {"날짜 선택"}
                </div>
            )}
        </InputStyle.Wrap>
    );
};

export default RangePicker;
