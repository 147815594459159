import React, { useEffect, useLayoutEffect, useState } from "react";
import Toc from "components/organisms/Toc";
import { ContainerStyle } from "./_style";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { TocDep1Props, TocDep2Props } from "components/organisms/_interface";
// page - router
import PinMap from "pages/PinMap";
import ArApply from "pages/ArApply";
import NotFound from "pages/NotFound";
// icon - menu
import IcoMenu01 from "assets/images/common/Menu/ico-menu-01.svg";
import IcoMenu02 from "assets/images/common/Menu/ico-menu-02.svg";
import IcoMenu01A from "assets/images/common/Menu/ico-menu-01-active.svg";
import IcoMenu02A from "assets/images/common/Menu/ico-menu-02-active.svg";
import Loading from "components/atoms/Loading";
import { useRecoilValue } from "recoil";
import { loaderState } from "recoil/globalState";
import TimeOut from "util/TimeOut";

const Container = ({ mainRoute }: { mainRoute?: string }) => {
    const loaderInfo = useRecoilValue(loaderState);
    const loaderShow = loaderInfo?.show;

    const navigate = useNavigate();
    const location = useLocation();
    const [hide, setHide] = useState<boolean>(false);
    const [menu, setMenu] = useState<TocDep1Props<TocDep2Props>[]>([
        {
            name: "시공 데이터",
            active: false,
            src: mainRoute + "/pinMap",
            ico: IcoMenu01,
            icoActive: IcoMenu01A,
        },
        {
            name: "AR 서비스 신청",
            active: false,
            src: mainRoute + "/arApply",
            ico: IcoMenu02,
            icoActive: IcoMenu02A,
        },
    ]);

    // 현재 path 맞게 메뉴 active
    useLayoutEffect(() => {
        const pathName = location?.pathname || "";
        if (pathName === mainRoute || pathName === "/") {
            setMenu((prev) => {
                let newArr = [...prev];
                let first = newArr[0];
                first.active = true;
                return newArr;
            });
        } else {
            setMenu((prev) => {
                let newArr = [...prev];
                newArr.map((ele) => (pathName.includes(ele.src || "") ? (ele.active = true) : (ele.active = false)));
                return newArr;
            });
        }
    }, [location, navigate]);

    //1뎁스 클릭
    const onDep1Click = (num: number) => {
        let newArr = [...menu];
        if (menu[num].dep2) {
            //2뎁스가 있는 메뉴일때
            newArr.map((i, index) => {
                //클릭한 것 active 토글 | 나머지 false
                index === num ? (newArr[index].active = !newArr[index].active) : (newArr[index].active = false);
            });
        } else {
            //2뎁스가 없는 메뉴일때
            newArr.map(({ dep2, src }, index) => {
                //클릭한 것 active | 나머지 false
                index === num ? (newArr[index].active = true) : (newArr[index].active = false);

                //모든 2뎁스 false
                dep2?.map(({ active, src }, idx) => {
                    dep2[idx].active = false;
                });

                //선택한 메뉴경로 이동
                if (index === num && src) {
                    navigate(src);
                }
            });
        }
        setMenu(newArr);
    };

    //2뎁스 클릭
    const onDep2Click = (num1: number, num2: number) => {
        let newArr = [...menu];
        newArr.map(({ dep2 }, index) => {
            //해당 메뉴의 1뎁스 active | 그 외 1뎁스 false
            index === num1 ? (newArr[index].active = true) : (newArr[index].active = false);

            dep2?.map(({ active, src }, idx) => {
                if (num1 === index && num2 === idx) {
                    // 선택한 2뎁스 메뉴경로 이동 및 active
                    dep2[idx].active = true;
                    navigate(dep2[idx].src);
                } else {
                    // 그 외 2뎁스 false
                    dep2[idx].active = false;
                }
            });
        });
        setMenu(newArr);
    };

    //새로고침이나 pathname 직접 입력하여 접근했을때
    useEffect(() => {
        // pathname이 있을때만
        if (location.pathname.split("/")[1]) {
            let newArr = [...menu];
            if (menu.filter(({ src }) => src === location.pathname).length) {
                // menu item의 src값과 pathname이 동일한 항목 active 처리
                newArr.map((i) => (i.src === location.pathname ? (i.active = true) : (i.active = false)));
            } else {
                // menu 2뎁스 item의 src값과 pathname이 동일한 항목 및 해당항목의 1뎁스 active 처리
                newArr.map(({ dep2 }, index) =>
                    dep2?.map((i) => {
                        if (i.src === location.pathname) {
                            newArr[index].active = true;
                            i.active = true;
                        } else {
                            i.active = false;
                        }
                    }),
                );
            }
            setMenu(newArr);
        }
    }, []);

    // 메뉴탭 감추기
    const onToggle = () => {
        setHide(!hide);
    };

    //  console.log(menu);
    return (
        <ContainerStyle.Contain>
            {/* GS인증용 - 타임아웃 */}
            <TimeOut />

            {/* 로딩바 */}
            <Loading show={loaderShow} />
            <Toc menu={menu} hide={hide} onDep1Click={onDep1Click} onDep2Click={onDep2Click} onToggle={onToggle} mainRoute={mainRoute} />

            <ContainerStyle.Wrap>
                <ContainerStyle.Content className={hide ? "full" : undefined}>
                    <ContainerStyle.Inner>
                        {/*라우터 영역*/}
                        <Routes>
                            <Route path={"/"} element={<PinMap />} />
                            <Route path={mainRoute} element={<PinMap />} />
                            <Route path={mainRoute + "/pinMap"} element={<PinMap />} />
                            <Route path={mainRoute + "/arApply"} element={<ArApply />} />
                            <Route path={"*"} element={<NotFound mainRoute={mainRoute} />} />
                        </Routes>

                        {/* <PopupTwoBtn close={() => console.log("close?")}>
                            <div style={{ background: "#fff" }}>여기가 영역인가요</div>
                        </PopupTwoBtn> */}
                    </ContainerStyle.Inner>
                </ContainerStyle.Content>
            </ContainerStyle.Wrap>
        </ContainerStyle.Contain>
    );
};

export default Container;
