import React, { useRef, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";
//ico
import IcoApply from "assets/images/common/ico-apply.svg";
import IcoRadioA from "assets/images/ArApply/ico-radio-active.svg";
import IcoInfo from "assets/images/ArApply/ico-info.svg";
import IcoClose from "assets/images/ArApply/ico-close.svg";
import icoCloseA from "assets/images/ArApply/ico-close-active.svg";
//component
import Checkbox from "components/atoms/Checkbox";
import MyButton from "components/atoms/MyButton";
import RangePicker from "components/atoms/RangePicker";
import EmptyTable from "components/organisms/EmptyTable";
import { InfoBoxStyle } from "components/organisms/_style";
//
import { useRecoilValue } from "recoil";
import { globalState, siteState } from "recoil/globalState";
import useQueryState from "hooks/my-react-query/useQueryState";
import { queryKeys } from "hooks/my-react-query/QueryKeys";
import { getArClient, postArRequest } from "service/api/viewer";
import { ArClientProps } from "pages/_interface";
import useMutateHook from "hooks/my-react-query/useMutateHook";

export const ApplyFormStyle = {
    Wrap: styled.div`
        width: calc(100% - 6px);
        padding-right: 6px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
    `,
    Guide: styled.div`
        color: #645349;
        background-color: #f8f3f0;
        border-radius: 8px;
        padding: 16px 20px 16px 20px;
        box-sizing: border-box;
        p.title {
            height: 19px;
            font-weight: 400;
            font-size: 1.6rem;
            margin-bottom: 8px;
        }
        ul {
            li {
                display: flex;
                margin-bottom: 5px;
                p {
                    height: 16px;
                    letter-spacing: -0.42px;
                    font-weight: 00;
                    &:nth-child(1) {
                        /* width: 250px; */
                        width: 45%;
                        flex-shrink: 0;
                    }
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    &::before {
                        flex-shrink: 0;
                        content: "";
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        background-color: #645349;
                        border-radius: 50%;
                    }
                }
            }
        }
    `,
    Divider: styled.div`
        width: 100%;
        border-bottom: 1px dashed #645349;
        margin: 12px 0;
    `,
    ApplyBtn: styled.button`
        width: 100%;
        height: 48px;
        color: #ffffff;
        background-color: #212122;
        border-radius: 8px;
    `,
};

// 회사(유효기간) 리스트 데이터 필터용(신청 전송용)
type ConstListProps = ArClientProps & {
    checked?: boolean;
    startDate?: string;
    endDate?: string;
};

// AR 서비스 신청 양식
function ApplyForm() {
    const scrollRef = useRef(null); // AR 서비스 신청 영역
    const globalInfo = useRecoilValue(globalState);
    const siteInfo = useRecoilValue(siteState);
    const siteId = siteInfo?.siteId;
    const viewerId = globalInfo?.viewerId;

    const [constList, setConstList] = useState<ConstListProps[]>([]); // 유효기간 선택 리스트 (체크 & 유효기간)

    // [React-Query]
    const { data: arClientData } = useQueryState<ArClientProps[]>(queryKeys.getArClient(siteId), () => getArClient({ siteId })); // 유효 기간 조회(신청가능한 회사 조회)

    // mutate - 서비스 신청
    const { mutate: mutateArRequest } = useMutateHook({
        fetchApi: postArRequest,
        successText: "서비스 신청이 완료되었습니다.",
        invalidQuery: [queryKeys.getArAccess(viewerId)],
    });

    // 화면이 작아져서 전체 스크롤 생기면,
    // 유효 기간 선택 영역 스크롤 제거 (이중 스크롤 방지)
    useEffect(() => {
        const setScroll = () => {
            let expireDateCont = document.getElementById("expireDateCont"); //유효기간 선택 Cont 영역
            expireDateCont.style.maxHeight = "240px"; // 기본 height 영역

            console.log(scrollRef.current?.scrollHeight);
            console.log(scrollRef.current?.offsetHeight);
            if (scrollRef.current?.scrollHeight >= scrollRef.current?.offsetHeight) {
                //  console.log("스크롤 발생");
                expireDateCont.style.maxHeight = "fit-content"; // height 영역 변경
            }
        };
        setScroll();
        window.addEventListener("resize", setScroll); // window - resize 이벤트
        return () => window.removeEventListener("resize", setScroll);
    }, [scrollRef.current?.scrollHeight]);

    // 유효기간 데이터 -> 체크,유효기간 내용 추가
    useEffect(() => {
        if (!isEmpty(arClientData)) {
            let newArr = arClientData?.map((ele: ArClientProps) => {
                return {
                    ...ele,
                    checked: false,
                    startDate: "",
                    endDate: "",
                };
            });
            setConstList(newArr);
        }
    }, [arClientData]);

    // AR 서비스 신청하기 - 서버요청
    const onClickRequest = async () => {
        console.log("신청하기");
        console.log(constList);

        // 체크된 항목 필터링
        let checkedArr = constList?.filter((ele) => ele?.checked);
        if (checkedArr?.length < 1) {
            alert("신청할 항목이 없습니다.");
            return;
        }
        // 유효기간 항목 null체크 (시작일/종료일 하나라도 없을때 true 반환)
        const isNotReady = checkedArr?.some((ele) => !ele?.startDate || !ele?.endDate);
        if (isNotReady) {
            alert("선택된 항목의 유효기간을 확인해주세요.");
            return;
        }

        // body 데이터
        let requestList = checkedArr?.map((ele) => {
            return {
                clientId: ele?.clientId,
                startDate: ele?.startDate,
                endDate: ele?.endDate,
            };
        });
        // mutate
        await mutateArRequest(
            {
                viewerId,
                body: {
                    requestList,
                },
            },
            {
                onSuccess(data, variables, context) {
                    // 성공시 constList 초기화
                    setConstList((prev: ConstListProps[]) => {
                        let newArr = [...prev];
                        return newArr.map((ele) => ({ ...ele, checked: false, startDate: "", endDate: "" }));
                    });
                },
            },
        );
    };

    return (
        <>
            <ApplyFormStyle.Wrap className="cScroll" ref={scrollRef}>
                {/* 인증 신청 */}
                <BasicInfo />

                {/* 유효기간 선택 */}
                <ExpireDateForm constList={constList} setConstList={setConstList} />

                {/* 가이드 */}
                <ApplyFormStyle.Guide>
                    <p className="title">시설물 신청</p>
                    <ul>
                        <li>
                            {/* <p>수자원 (상수/오수/우수/재활용)</p> */}
                            {/* <p>한전 (전기/전기[전력간선]/전기[전력인입])</p> */}
                            <p>수자원 (상수/하수)</p>
                            <p>한전 (전기)</p>
                        </li>
                        <li>
                            <p>가스공사 (가스)</p>
                            <p>한국석유공사 (송유)</p>
                        </li>
                        <li>
                            <p>통신3사 (통신)</p>
                            <p>난방공사 (난방)</p>
                        </li>
                    </ul>
                </ApplyFormStyle.Guide>

                <div style={{ marginTop: "auto" }}>
                    <ApplyFormStyle.Divider></ApplyFormStyle.Divider>
                    {/* 신청하기 버튼 */}
                    <MyButton text="신청하기" colorType="black" height={48} radius={8} leftIcon={IcoApply} onClick={onClickRequest} />
                </div>
            </ApplyFormStyle.Wrap>
        </>
    );
}

export default ApplyForm;

// 인증 신청 -> 종류 -> 라디오 버튼, info 버튼
const BasicInfoStyle = {
    RadioContent: styled.div`
        display: flex;
        align-items: center;
    `,
    RadioBtn: styled.div<{ ico?: string }>`
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 1.6rem;
        font-weight: 400;
        span.icon {
            width: 18px;
            height: 18px;
            display: inline-block;
            background: ${(p) => (p.ico ? `url(${p.ico}) no-repeat center` : "#f8f8f8")};
            border: ${(p) => (p.ico ? "none" : "1px solid #e5e5ec")};
            border-radius: 9px;
            box-sizing: border-box;
        }
    `,
    InfoBtn: styled.span<{ ico?: string }>`
        position: relative;
        width: 24px;
        height: 24px;
        display: inline-block;
        background: ${(p) => (p.ico ? `url(${p.ico}) no-repeat center` : "#f8f8f8")};
        cursor: pointer;
    `,
    InfoBox: styled.div<{ icoClose?: string; icoCloseA?: string }>`
        display: flex;
        width: 190px;
        height: 50px;
        padding: 8px 9px;
        box-sizing: border-box;
        position: absolute;
        top: -62px;
        right: 0px;
        background-color: #645349;
        border-radius: 4px;
        box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.1);
        p {
            font-size: 1.2rem;
            line-height: 18px;
            font-weight: 200;
            color: #ffffff;
        }
        button.closeBtn {
            width: 16px;
            height: 16px;
            background: ${(p) => (p.icoClose ? `url(${p.icoClose}) no-repeat center` : "")};
            transition: all 0.3s;
            &:hover {
                background-image: ${(p) => (p.icoCloseA ? `url(${p.icoCloseA})` : "")};
            }
        }
        &::after {
            position: absolute;
            bottom: -11px;
            right: 2px;
            content: " ";
            height: 0;
            z-index: -1;
            border-bottom: 20px solid;
            border-left: 10px solid rgba(0, 0, 0, 0);
            border-right: 10px solid rgba(0, 0, 0, 0);
            color: #645349;
            transform: rotate(-180deg);
        }
    `,
};
// 상단 영역 [인증 신청]
export function BasicInfo() {
    const globalInfo = useRecoilValue(globalState);
    const siteInfo = useRecoilValue(siteState);

    const [isOpenGuide, setIsOpenGuide] = useState(false); // 긴급 옆 info 박스

    return (
        <>
            <InfoBoxStyle.Wrap>
                <InfoBoxStyle.Box>
                    <InfoBoxStyle.Title style={{ height: "50px" }}>인증 신청</InfoBoxStyle.Title>
                    <InfoBoxStyle.Ul>
                        <InfoBoxStyle.Li>
                            <InfoBoxStyle.SubTitle>
                                <p>종류</p>
                            </InfoBoxStyle.SubTitle>
                            <BasicInfoStyle.RadioContent>
                                <BasicInfoStyle.RadioBtn ico={IcoRadioA}>
                                    <span className="icon"></span> 일반
                                </BasicInfoStyle.RadioBtn>
                                <BasicInfoStyle.RadioBtn style={{ marginLeft: "20px" }}>
                                    <span className="icon"></span> 긴급
                                </BasicInfoStyle.RadioBtn>
                                <BasicInfoStyle.InfoBtn ico={IcoInfo} style={{ marginLeft: "2px" }} onClick={() => setIsOpenGuide(true)}>
                                    {isOpenGuide && (
                                        <BasicInfoStyle.InfoBox icoClose={IcoClose} icoCloseA={icoCloseA}>
                                            <p>긴급신청은 전용 어플리케이션을 이용해주세요</p>
                                            <button
                                                className="closeBtn"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setIsOpenGuide(false);
                                                }}
                                            ></button>
                                        </BasicInfoStyle.InfoBox>
                                    )}
                                </BasicInfoStyle.InfoBtn>
                            </BasicInfoStyle.RadioContent>
                        </InfoBoxStyle.Li>
                        <InfoBoxStyle.Li>
                            <InfoBoxStyle.SubTitle>
                                <p>현장명</p>
                            </InfoBoxStyle.SubTitle>
                            <p>{siteInfo?.siteName || "-"}</p>
                        </InfoBoxStyle.Li>
                        <InfoBoxStyle.Li>
                            <InfoBoxStyle.SubTitle>
                                <p>연락처</p>
                            </InfoBoxStyle.SubTitle>
                            <p>{globalInfo?.phone || "-"}</p>
                        </InfoBoxStyle.Li>
                    </InfoBoxStyle.Ul>
                </InfoBoxStyle.Box>
            </InfoBoxStyle.Wrap>
        </>
    );
}

// 하단 영역 [유효기간 선택]
export function ExpireDateForm({ constList, setConstList }: { constList?: ConstListProps[]; setConstList?: React.Dispatch<React.SetStateAction<ConstListProps[]>> }) {
    // Check box 버튼 이벤트
    const onChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;

        setConstList((prev: ConstListProps[]) => {
            let newArr = [...prev];
            // return newArr.map((ele) => (ele.clientName === name ? { ...ele, checked } : { ...ele }));
            if (!checked) {
                return newArr.map((ele) => (ele.clientName === name ? { ...ele, checked, startDate: "", endDate: "" } : { ...ele }));
            }
            return newArr.map((ele) => (ele.clientName === name ? { ...ele, checked } : { ...ele }));
        });
    };

    // Range Picker - onChange 이벤트
    // 날짜 데이터 선택시 체크박스 자동 선택
    const onChangeRangeDate = (startDate: string, endDate: string, target: string) => {
        console.log(startDate, endDate, target);

        setConstList((prev: ConstListProps[]) => {
            let newArr = [...prev];
            return newArr.map((ele) => (ele.clientName === target ? { ...ele, startDate, endDate, checked: true } : { ...ele }));
        });
    };

    //  console.log(constList);

    return (
        <>
            <InfoBoxStyle.Wrap>
                <InfoBoxStyle.Box>
                    <InfoBoxStyle.Title style={{ height: "50px" }}>유효 기간 선택</InfoBoxStyle.Title>
                    <InfoBoxStyle.Ul id="expireDateCont" className="cScroll" style={{ gap: "10px", padding: "12px 20px" }}>
                        {/* 회사 목록 데이터 없을때  */}
                        {isEmpty(constList) && <EmptyTable height="100px" nullText="신청가능한 회사가 없습니다." borderNone />}

                        {/* 신청 가능한 회사 목록 - 체크박스 & 유효기간 선택  */}
                        {constList?.map((ele, index) => (
                            <InfoBoxStyle.Li key={ele?.clientId} style={{ height: "fit-content" }}>
                                <InfoBoxStyle.SubTitle className="flex ai_center" style={{ gap: "10px" }}>
                                    <Checkbox id={ele?.clientId} name={ele?.clientName} text={ele?.clientName} onChange={onChangeCheck} checked={ele?.checked} />
                                </InfoBoxStyle.SubTitle>

                                <RangePicker name={ele?.clientName} width={214} height={40} onCustomChange={onChangeRangeDate} value={[ele?.startDate, ele?.endDate]} />
                            </InfoBoxStyle.Li>
                        ))}
                    </InfoBoxStyle.Ul>
                </InfoBoxStyle.Box>
            </InfoBoxStyle.Wrap>
        </>
    );
}
