import styled from "styled-components";
import { ContProps } from "./_interface";
// img
import BG from "assets/images/login/BG.webp";
import BGpng from "assets/images/login/BG.png";
//ico
import mainLogo from "assets/images/common/main-logo.svg";
import logoMove from "assets/images/login/ico-logo-move.svg";
import IcoInfo from "assets/images/common/ico-info.svg";

export const ContStyle = {
    Wrap: styled.div`
        width: 100%;
        height: 100%;
        border-radius: 38px;
        padding: 30px 40px;
        box-sizing: border-box;
        background: #fff;
        display: flex;
        position: relative;
        &::before {
            content: "";
            width: 96px;
            height: 8px;
            background-color: #222221;
            border-radius: 0 0 8px 8px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }
    `,
    Main: styled.div`
        flex: 1;
        height: 100%;
        /* border-radius: 38px; */
    `,
    Head: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        padding: 0 0 24px 0;
        box-sizing: border-box;
    `,
    Title: styled.strong<ContProps>`
        display: flex;
        align-items: center;
        font-size: 2.2rem;
        font-weight: 400;
        &:before {
            content: "";
            width: 32px;
            height: 32px;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 14px;
            display: ${(p) => (p.ico ? "block" : "none")};
            background-image: ${(p) => (p.ico ? `url(${p.ico})` : "none")};
        }
    `,
    Cont: styled.div`
        width: 100%;
        position: relative;
        z-index: 10;
        background: #fff;
        height: calc(100% - 50px);
        /* border-radius: 20px; */
        overflow: hidden;
    `,
    //  Inner: styled.div`
    //      height: calc(100% - 36px);
    //      padding: 18px 40px;
    //  `,
    TopArea: styled.div`
        height: 48px;
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        .addBtn {
        }
    `,
};

export const LoginStyle = {
    BackGround: styled.div`
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url(${BG}) no-repeat center;
        background-size: cover;
        &.no-webp {
            background: url(${BGpng}) no-repeat center;
        }
    `,
    Wrap: styled.div`
        display: flex;
        flex-direction: column;
        gap: 50px;
    `,
    Box: styled.div`
        width: 610px;
        height: 680px;
        border-radius: 40px;
        background-color: #fff;
        position: relative;
        &:before {
            content: "";
            width: 96px;
            height: 8px;
            background-color: #222221;
            border-radius: 0 0 8px 8px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }
    `,
    Inner: styled.div`
        width: 100%;
        height: 100%;
        padding: 90px 90px 40px 90px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
    Logo: styled.div`
        width: 120px;
        height: 123px;
        background: url(${mainLogo}) no-repeat center;

        margin-bottom: 44px;
    `,
    LoginBtn: styled.button`
        width: 100%;
        height: 56px;
        /* margin-top: auto; */
        border-radius: 16px;
        font-size: 1.6rem;
        font-weight: 300;

        position: relative;
        bottom: 0px;
        z-index: 1;
        background-image: linear-gradient(to right, #ffd801, #ffed40);
        &::before {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            content: "";
            transition: opacity 0.3s linear;
            opacity: 0;
        }
        &:hover {
            &::before {
                background-image: linear-gradient(to right, #ffbb00, #ffd901);
                opacity: 1;
            }
        }
        &.disabled {
            background: #b0b0b0;
            color: #dddddd;
            cursor: default;
            &:hover {
                &::before {
                    opacity: 0;
                }
            }
        }
    `,
    Copyright: styled.div`
        font-size: 1.4rem;
        color: #645349;
        text-align: center;
        margin-top: auto;
    `,
    FooterLogo: styled.div`
        width: 126px;
        height: 24px;
        margin: 0 auto;
        background: url(${logoMove}) no-repeat center;
        margin-top: auto;
    `,

    FormWrap: styled.div`
        display: flex;
        flex-direction: column;
        gap: 10px;
    `,
    FormRow: styled.div`
        /* display: flex; */
        /* gap: 12px; */
        .flex {
            gap: 12px;
        }
        .subText {
            font-size: 1.2rem;
            margin-top: 10px;
            color: #3391ff;
            &.error {
                color: red;
            }
        }
        button {
            background-color: #645349;
            border-radius: 16px;
            border: none;
            span {
                font-weight: 300;
            }
            /* font-size: 1.6rem; */
            /* color: #fff; */
        }
    `,
    Divider: styled.div`
        width: 100%;
        height: 1px;
        background-color: #dedede;
        margin: 24px 0;
    `,
    Notice: styled.div`
        width: 100%;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        gap: 6px;
        span {
            color: #645349;
            line-height: 20px;
        }
        span.ico {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url(${IcoInfo}) no-repeat center;
        }
    `,
};

//AR 서비스 신청
export const ArApplyStyle = {
    Wrap: styled.div`
        height: 100%;
        box-sizing: border-box;
        display: flex;
        gap: 10.5px;
    `,
    Section: styled.div`
        width: 50%;
        height: 100%;
        .tab {
            width: fit-content;
            height: 48px;
            padding: 0 24px;
            font-size: 1.6rem;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fdf4bd;
            border: 2px solid #ffe500;
            border-bottom: 0;
            box-sizing: border-box;
            border-radius: 8px 8px 0 0;
        }
        .cont {
            width: 100%;
            height: calc(100% - 48px);
            border: 2px solid #ffe500;
            border-radius: 0 24px 24px;
            padding: 20px 24px;
            box-sizing: border-box;
        }
        &.gray {
            .tab {
                background-color: #fcfcfc;
                border-color: #ebebeb;
            }
            .cont {
                border: 2px solid #ebebeb;
            }
        }
    `,
    Divider: styled.div`
        width: 1px;
        height: calc(100% - 2px);
        border-right: 1px dashed #ffe500;
        box-sizing: border-box;
        margin-top: 3px;
    `,
};
