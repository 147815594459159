import { PopupBoxStyle, PopupCenterStyle, PopupStyle } from "./_style";
import { PopupProps } from "./_interface";

// 하단 버튼 2개까지 가능한 팝업  close, confirm 유무 //
function PopupTwoBtn({ width, height, src, title, text, close, leftFunc, rightFunc, leftText = "닫기", rightText, children }: PopupProps) {
    return (
        <PopupCenterStyle.Dimmed>
            <PopupCenterStyle.Wrap width={width} height={height}>
                <PopupCenterStyle.Head>
                    <PopupStyle.Title src={src}>{title}</PopupStyle.Title>
                    {close && (
                        <PopupStyle.Close type={"button"} onClick={close}>
                            <span className={"hidden"}>닫기</span>
                        </PopupStyle.Close>
                    )}
                </PopupCenterStyle.Head>
                <PopupCenterStyle.Cont>
                    {/* 주요 콘텐츠 */}
                    {children ? children : null}

                    {/* 하단 버튼 */}
                    <PopupCenterStyle.FooterWrap>
                        {/* 단순 팝업 "닫기" 또는 "거절", "삭제" 요청 등 */}
                        {leftText && (
                            <button
                                className="cancelBtn"
                                onClick={async () => {
                                    if (leftFunc) {
                                        await leftFunc();
                                    } else if (close) {
                                        await close();
                                    }
                                }}
                            >
                                {leftText}
                            </button>
                        )}

                        {/* "확인", "승인" 버튼 기능 */}
                        {rightFunc && (
                            <button
                                className="confirmBtn"
                                onClick={async () => {
                                    if (rightFunc) {
                                        await rightFunc();
                                    }
                                }}
                            >
                                <p>{rightText ? rightText : "확인"}</p>
                            </button>
                        )}
                    </PopupCenterStyle.FooterWrap>
                </PopupCenterStyle.Cont>
            </PopupCenterStyle.Wrap>
        </PopupCenterStyle.Dimmed>
    );
}

export default PopupTwoBtn;
