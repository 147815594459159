import { TagStyle } from "./_style";

// AR신청 상태 라벨 형태
function StatusTag({ type }: { type?: string }) {
    // 상태 종류
    const StatusType = [
        {
            type: "1",
            title: "승인",
            color: "#ffe500",
            backgroundColor: "#1b1b1b",
        },
        {
            type: "2",
            title: "대기",
            color: "#ffffff",
            backgroundColor: "#a0a5b1",
        },
    ];

    if (type) {
        const status = StatusType.find((ele) => ele.type === type);
        return (
            <TagStyle.Wrap color={status?.color} borderColor={status?.backgroundColor} backgroundColor={status?.backgroundColor}>
                {status?.title}
            </TagStyle.Wrap>
        );
    }

    return null;
}

export default StatusTag;
