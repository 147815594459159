import { useState, useCallback } from "react";

type UseInputAreaProps = [any, React.Dispatch<React.SetStateAction<object>>, (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLLIElement> | React.ChangeEvent<HTMLTextAreaElement>) => void, () => void, (name: string) => void, (value: any, name?: string) => void];

/* custom hook 만들기 */
function useInputArea(initialForm: object): UseInputAreaProps {
    const [form, setForm] = useState(initialForm);

    // change
    const onChange = (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLLIElement> | React.ChangeEvent<HTMLTextAreaElement>, str?: string): void => {
        if (str) {
            const { ariaValueText } = e.currentTarget as HTMLLIElement; // li 태그 (select 박스)
            setForm({
                ...form,
                [str]: ariaValueText,
            });
        } else {
            const { name, value } = e.currentTarget as HTMLInputElement;
            setForm({
                ...form,
                [name]: value,
            });
        }
    };

    //모든 input => form 초기화
    const resetAll = useCallback((): void => setForm(initialForm), [initialForm]);

    //특정 input 초기화
    const resetOne = useCallback((name: string): void => {
        setForm((prev) => {
            return { ...prev, [name]: "" };
        });
    }, []);

    // date picker 일때
    const onChangeDate = useCallback((value: any, name?: string): void => {
        setForm((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    return [form, setForm, onChange, resetAll, resetOne, onChangeDate];
}

export default useInputArea;
