import React, { useEffect } from "react";
import { PinInfoStyle } from "./_style";
import EmptyTable from "components/organisms/EmptyTable";
import PopupCenter from "popup/PopupCenter";
import SwiperForPopup from "popup/SwiperForPopup";
import { isEmpty } from "lodash";
import { SwiperSlide } from "swiper/react";

// 가운데 위치 - 이미지 크게보기
function PopupLargeImg({ mainTarget, imgList, initNum, close }: { mainTarget?: string; imgList?: string[]; initNum?: number; close?: () => void }) {
    return (
        <PopupCenter
            title={"사진 보기"}
            width={"640px"}
            height={"688px"}
            close={close}
            children={
                <>
                    {/* 이미지 영역 */}
                    {isEmpty(imgList) ? (
                        <EmptyTable height="640px" nullText="이미지가 없습니다." borderNone />
                    ) : (
                        <div style={{ width: "100%", height: "100%", padding: "20px", boxSizing: "border-box" }}>
                            <SwiperForPopup
                                initialSlide={initNum}
                                perView={1}
                                content={
                                    <>
                                        {!isEmpty(imgList) ? (
                                            <PinInfoStyle.ImageWrap className="cScroll">
                                                {imgList?.map((img: any) => (
                                                    <SwiperSlide key={img?.imgUrl}>
                                                        <img src={`${mainTarget}${img?.imgUrl}`} alt="" width={"592px"} height={"592px"} />
                                                    </SwiperSlide>
                                                ))}
                                            </PinInfoStyle.ImageWrap>
                                        ) : null}

                                        {/* 이미지 영역 - 테스트용 */}
                                        {/* <SwiperSlide>
                                 <img src="https://item.kakaocdn.net/do/fd0050f12764b403e7863c2c03cd4d2d7154249a3890514a43687a85e6b6cc82" alt="짱구" />
                              </SwiperSlide>

                              <SwiperSlide>
                                 <img src="https://mblogthumb-phinf.pstatic.net/MjAyMDA4MzBfMjQg/MDAxNTk4NzUxNzAzNjE0.UvBE86GZtVJXJsEdnTuFGUUXAB7D1wr_dFLyKnV1HQcg.lrtgetgW5sU7VAwZ9LVTwrPez8J0DNw8irz5fogDEPEg.JPEG.lahaneui123/1475313251109.jpg?type=w800" alt="짱구" />
                              </SwiperSlide> */}
                                    </>
                                }
                            />
                        </div>
                    )}
                </>
            }
        />
    );
}

export default React.memo(PopupLargeImg);
