import { TwoBtnPopup } from "popup/popupjs/component/PopupType";
import styled from "styled-components";

const PopupVersionSt = {
    Wrap: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin-bottom: 26px;
    `,
    Title: styled.p`
        color: #645349;
        font-weight: 400;
        font-size: 1.6rem;
    `,
    Cont: styled.div`
        p {
            font-weight: 400;
            font-size: 1.6rem;
            text-align: center;
        }
    `,
};

function PopupVersion({ close }: { close: any }) {
    return (
        <>
            {/* usePopup 훅 대신 직접 컴포넌트 재사용 */}
            <TwoBtnPopup key="null" data={{ leftFunc: close }}>
                <PopupVersionSt.Wrap>
                    <PopupVersionSt.Title>버전 정보</PopupVersionSt.Title>
                    <PopupVersionSt.Cont>
                        <p>스마트핀 v1.0</p>
                        <p>스마트핀 WEB v1.0</p>
                    </PopupVersionSt.Cont>
                </PopupVersionSt.Wrap>
            </TwoBtnPopup>
        </>
    );
}

export default PopupVersion;
