import styled from "styled-components";
import IcoNaviPrev from "assets/images/common/ico-swiper-prev.svg";
import IcoNaviNext from "assets/images/common/ico-swiper-next.svg";
import IcoNaviPrevA from "assets/images/common/ico-swiper-prev-active.svg";
import IcoNaviNextA from "assets/images/common/ico-swiper-next-active.svg";
import { PopupProps } from "./_interface";

export const SwiperStyle = {
    Wrap: styled.div`
        width: 100%;
        height: 100%;
        position: relative;
        .swiper {
            height: 100%;
            .swiper-wrapper {
                height: 100%;
            }
        }
        .swiper-slide {
            display: flex;
            justify-content: center;
            /* 이미지 사이즈 */
            img {
                /* width: 100%; */
            }
        }
    `,
    Navi: styled.div`
        width: 100%;
        padding: 0 24px;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999999;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btnSwiperPrev,
        .btnSwiperNext {
            width: 32px;
            height: 32px;
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0.4);
            background-repeat: no-repeat;
            background-position: center;
            &.swiper-button-disabled {
                opacity: 0.5;
            }
        }
        .btnSwiperPrev {
            background-image: url(${IcoNaviPrev});
            &:hover {
                background-image: url(${IcoNaviPrevA});
            }
        }
        .btnSwiperNext {
            background-image: url(${IcoNaviNext});
            &:hover {
                background-image: url(${IcoNaviNextA});
            }
        }
    `,
    Footer: styled.div`
        width: 100%;
        position: absolute;
        bottom: 14px;
        margin: 0 auto;
        z-index: 9;
        /* 페이지 네이션 스타일 */
        #swiper-pagination {
            margin: 0 auto;
            width: fit-content;
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 16px;
            padding: 7px 16px 8px 15px;
            color: #fff;
            font-size: 1.4rem;
            span {
                font-weight: 200;
                color: #fff;
                &.swiper-pagination-current {
                    color: #ffe500;
                    margin-right: 2px;
                }
                &.swiper-pagination-total {
                    margin-left: 2px;
                }
            }
        }
    `,
};

// 기본 팝업 스타일
export const PopupStyle = {
    Wrap: styled.div<PopupProps>`
        width: ${(p) => (p.width ? p.width : "460px")};
        height: ${(p) => (p.height ? p.height : "300px")};
        position: absolute;
        top: 30px;
        right: 10px;
        z-index: 1000;

        /* border: 1px solid red; */
    `,
    Head: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 76px;
        padding: 0 30px;
        box-sizing: border-box;
        background: #ffdd32;
        border-radius: 38px 38px 0 0;
    `,
    Title: styled.strong<PopupProps>`
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        &:before {
            content: "";
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 10px;
            background-image: ${(p) => (p.src ? `url(${p.src})` : "none")};
            display: ${(p) => (p.src ? "block" : "none")};
        }
    `,
    // 아이콘 없이
    Close: styled.button`
        position: relative;
        width: 24px;
        height: 24px;
        &:before,
        &:after {
            position: absolute;
            left: 50%;
            top: 50%;
            content: "";
            display: block;
            width: 20px;
            height: 1px;
            background: #515151;
        }
        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    `,
    Cont: styled.div`
        width: 100%;
        height: calc(100% - 76px);
    `,
    Inner: styled.div<{ height?: string }>`
        &:not(:last-child) {
            border-bottom: 2px dashed #babae8;
        }
        padding: 22px 30px;
        height: ${(p) => (p.height ? p.height : "")};
    `,
};

// 팝업 - 가운데 위치 속성
export const PopupCenterStyle = {
    Dimmed: styled.div`
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
    `,
    Wrap: styled(PopupStyle.Wrap)`
        width: ${(p) => (p.width ? p.width : "90%")};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `,
    Head: styled(PopupStyle.Head)`
        height: 56px;
        background-color: #fff;
        border-radius: 12px;
        /* border-bottom: 1px solid #d5d5d5; */
        position: relative;
        &:after {
            position: absolute;
            bottom: 0;
            left: 16px;
            content: "";
            width: calc(100% - 32px);
            height: 1px;
            background-color: #d5d5d5;
        }
    `,
    Cont: styled(PopupStyle.Cont)`
        height: calc(100% - 56px);
        /* padding: 24px 0 0 0; */
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 12px;
        overflow: hidden;

        /* border: 1px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
    ImgWrap: styled.div`
        width: 100%;
        height: 100%;
        padding: 24px;
    `,

    FooterWrap: styled.div`
        margin-top: auto;
        width: 100%;
        height: 56px;
        flex-shrink: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        button {
            height: 100%;
            flex: 1;
            flex-shrink: 0;
            font-size: 1.6rem;
            color: #fff;
            background-color: #212122;
            transition: all.3s;
            &.confirmBtn {
                p {
                    font-size: 1.6rem;
                    line-height: 36px;
                    border-left: 0.5px solid #454545;
                }
            }
            &:hover {
                background-color: #525252;
            }
        }
    `,
};

// 팝업 - Sliding - 오른쪽
export const SlidingStyle = {
    Dimmed: styled.div`
        width: 0;
        height: 0;
        background: rgba(0, 0, 0, 0.2);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        &.isOpen {
            width: 100%;
            height: 100vh;
        }
    `,
    Wrap: styled.div<PopupProps>`
        width: ${(p) => (p.width ? p.width : "460px")};
        height: ${(p) => (p.height ? p.height : "calc(100% - 40px)")};
        position: fixed;
        top: 20px;
        right: ${(p) => (p.width ? "-" + p.width : "460px")};
        z-index: 1000;
        transition: all 0.3s;
        border-radius: 14px;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.35);
        &.isOpen {
            right: 20px;
        }
    `,
    Head: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;
        padding: 0 24px;
        box-sizing: border-box;
        background: #ffe500;
        border-radius: 14px;
        &::before {
            content: "";
            width: 72px;
            height: 4px;
            background-color: #222221;
            border-radius: 0 0 4px 4px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }
    `,
    Title: styled.div``,
    Close: styled.div``,
    Cont: styled.div`
        width: 100%;
        height: calc(100% - 56px);
        background-color: #fff;
        border-radius: 14px;
        padding: 14px 0 16px 0;
        box-sizing: border-box;
    `,
};

// 팝업 - two btn
export const PopupBoxStyle = {
    Dimmed: styled.div`
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
    `,
    Wrap: styled.div`
        width: 100%;
        height: 100%;
        /* max-width: 1400px; */
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    Box: styled.div<{ width?: number | string; height?: number | string }>`
        width: ${(p) => (p.width ? p.width : "100%")};
        height: ${(p) => (p.height ? p.height : "100%")};
    `,
    Head: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 76px;
        padding: 0 30px;
        box-sizing: border-box;
        background: #5852f4;
        border-radius: 38px 38px 0 0;
    `,
    Title: styled.strong<PopupProps>`
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #fff;
        font-size: 2rem;
        &:before {
            content: "";
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 10px;
            background-image: ${(p) => (p.src ? `url(${p.src})` : "none")};
            display: ${(p) => (p.src ? "block" : "none")};
        }
    `,
    Close: styled.button`
        position: relative;
        width: 24px;
        height: 24px;
        &:before,
        &:after {
            position: absolute;
            left: 50%;
            top: 50%;
            content: "";
            display: block;
            width: 26px;
            height: 1px;
            background: #fff;
        }
        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    `,
    Cont: styled.div`
        height: calc(100% - 76px); // 팝업 헤더 제외 영역
        background-color: #fff;
        border-radius: 0 0 38px 38px;
        padding: 32px 38px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        > .message {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            text-align: center;
            word-break: break-all;
            font-size: 2rem;
            font-weight: 600;
        }
        > .btnWrap {
            /* margin-top: auto; */
            width: 100%;
            display: flex;
            gap: 10px;
            button {
                width: 100%;
                height: 56px;
                font-size: 1.6rem;
                font-weight: 600;
                border-radius: 14px;
                &.confirmBtn {
                    color: #fff;
                    background-color: #5852f4;
                }
                &.cancelBtn {
                    color: #5852f4;
                    border: 1px solid #5852f4;
                }
            }
        }
        > .cScroll {
            height: 100%;
        }
    `,
    Dashed: styled.div`
        margin-top: auto;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 62px;
        background: #fff;
        > div {
            position: relative;
            flex: 1;
            background: #fff; //
            margin-right: 5px;
            &:before {
                position: absolute;
                top: 50%;
                left: 0;
                content: "";
                display: block;
                width: 100%;
                border-top: 2px dashed #babae8;
            }
        }
    `,
};
