import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { LoginStyle } from "../_style";
// ico
import IcoPhone from "assets/images/login/ico-phone.svg";
import IcoPhoneA from "assets/images/login/ico-phone-active.svg";
import IcoBuilding from "assets/images/login/ico-building.svg";
import IcoBuildingA from "assets/images/login/ico-building-active.svg";
import IcoMail from "assets/images/login/ico-mail.svg";
import IcoMailA from "assets/images/login/ico-mail-active.svg";
//
import Input from "components/atoms/Input";
import MyButton from "components/atoms/MyButton";
import useInputArea from "hooks/useInputArea";
import Select from "components/atoms/Select";
import usePopup from "popup/popupjs/hook/usePopup";
import useMutateHook from "hooks/my-react-query/useMutateHook";
//
import { getSiteByViewer, postSMSCode, postSMSCodeValid } from "service/api/viewer";
import useQueryState from "hooks/my-react-query/useQueryState";
import { isEmpty, omit } from "lodash";
import { queryKeys } from "hooks/my-react-query/QueryKeys";
import { useRecoilState } from "recoil";
import { globalState, siteState } from "recoil/globalState";
import { getCurrentTime, maxLengthCheck } from "service/global";

type UserSiteRespon = {
    siteId?: string; // 현장 아이디
    siteName?: string; // 현장 이름
    isValid?: 0 | 1; // 인증 유효 시간 여부
    viewerId?: string; // 작업자 아이디
    mappingKey?: any;
};

// sms로그인 화면 & 현장 변경 팝업 사용
function LoginForm({ isForLogin, getInfo }: { isForLogin?: boolean; getInfo?: any }) {
    const [globalInfo, setGlobalInfo] = useRecoilState(globalState); //recoil
    const [siteInfo, setSiteInfo] = useRecoilState(siteState); //recoil

    const [timerStart, setTimerStart] = useState(false); // 타이머

    const { addPopup, removeCurrentPopup } = usePopup(); //결과 팝업
    const [form, setForm, onChange] = useInputArea({ phone: "", siteId: "", code: "" }); // input 내용
    const [siteOptions, setSiteOptions] = useState([{ text: "", value: "" }]);
    const [stepState, setStepState] = useState([
        {
            step: "1",
            show: isForLogin, // 로그인 화면용 일때
            isDone: false, // 성공유무 상관없이 요청 여부
            isSuccess: false,
        },
        {
            step: "2",
            show: !isForLogin, // 로그인 화면용 아닐때 바로 표시
            isDone: false,
            isSuccess: false, // 성공 유무
            subText: "", // 하단에 보여줄 텍스트
            buttonText: "인증번호 전송",
        },
        {
            step: "3",
            show: false,
            isDone: false,
            isSuccess: false,
            subText: "",
        },
    ]);
    const [params, setParams] = useState({ phone: globalInfo.phone || "" }); // 기존 phone 데이터 있을 시 미리 셋팅

    // [React-Query]
    const { data: siteArr } = useQueryState<UserSiteRespon[]>(queryKeys.getSiteByViewer(params.phone), () => getSiteByViewer({ ...params }), {
        enabled: !!params.phone, // 휴대폰 번호 유무
        onSuccess(data) {
            // 결과 데이터 없을때
            if (isEmpty(data)) {
                removeCurrentPopup();
                addPopup({
                    key: null,
                    data: {
                        type: "simple",
                        text: "소속된 현장이 없습니다.",
                    },
                });
                return;
            }
            // 현장 목록 데이터 있을때 - 1단계(폰입력) 성공 처리
            setStepState((prev) => {
                return [...prev].map((ele) => {
                    if (ele.step === "1") return { ...ele, isDone: true, isSuccess: true };
                    if (ele.step === "2") return { ...ele, show: true };
                    return ele;
                });
            });
            //** recoil 저장 - 현장 목록
            setSiteInfo((prev) => ({
                ...prev,
                siteList: data,
            }));
        },
        onError(err: any) {
            console.log(err?.response?.data?.message);
            removeCurrentPopup();
            addPopup({
                data: {
                    type: "simple",
                    text: err?.response?.data?.message || "에러 발생",
                },
            });
            // 초기화 - [로그인화면] 연락처 수정 없이 요청시에도, refetch 되도록
            setParams({ phone: "" });
        },
        onSettled() {},
    }); // 작업자(phone)소속 현장 목록 조회

    // [mutate] - 인증번호 전송
    const { mutate: mutateSMS } = useMutateHook({ fetchApi: postSMSCode });
    // [mutate] - 인증번호 확인
    const { mutate: mutateSMSValid } = useMutateHook({ fetchApi: postSMSCodeValid });

    // 현장 SelectBox 데이터 -> option 형태로 변경
    useLayoutEffect(() => {
        if (siteArr) {
            // **selectBox에서 사용
            let newArr: any[] = [];
            siteArr?.map((ele) => {
                newArr.push({ text: ele.siteName, value: ele.siteId });
            });
            setSiteOptions(newArr);
        }
    }, [siteArr, setSiteInfo]);

    // ** 현장 SelectBox 선택 trigger
    useEffect(() => {
        // (현장변경 팝업)
        if (!isForLogin) getInfo({ siteInfo: { siteName: "", siteId: "" }, viewerInfo: { viewerId: "", phone: "" } }); // 선택된 현장 - 초기화

        // 선택된 현장 아이디
        if (form?.siteId) {
            const selectedSite = siteInfo.siteList?.find((ele) => ele.siteId === form?.siteId);
            const isValidSite = selectedSite.isValid; // 인증 유효 여부(1:인증유효 상태)
            // 재인증이 필요한 현장일때
            if (isValidSite === 0) {
                setStepState((prev: any) => {
                    return [...prev].map((ele) => (ele.step === "2" ? { ...ele, isDone: false, isSuccess: false, subText: "", buttonText: "인증번호 전송" } : { ...ele }));
                });
            }
            // 재인증 필요없는 현장일때
            if (isValidSite === 1) {
                // (인증 완료 전으로) 초기화
                setStepState((prev) => {
                    return [...prev].map((ele) => {
                        if (ele.step === "2") return { ...ele, isDone: true, isSuccess: false, subText: "", buttonText: "인증 확인" };
                        if (ele.step === "3") return { ...ele, show: false, isDone: false, isSuccess: false };
                        return ele;
                    });
                });
            }
        }
    }, [siteInfo, form.siteId, getInfo, isForLogin]);

    // 1단계) 휴대폰 번호 입력 후 현장 조회 버튼 이벤트
    const onClickGetSite = () => {
        // 빈칸 체크
        if (!form?.phone) {
            removeCurrentPopup();
            addPopup({
                data: {
                    type: "simple",
                    text: "휴대폰 번호를 입력하세요.",
                },
            });
            return;
        }
        // 조회 파라미터 업데이트 - Phone
        setParams((prev) => {
            let newParam = { ...prev };
            newParam.phone = form?.phone;
            return newParam;
        });
    };

    // 2단계) [인증번호 전송] or [인증 확인] 버튼 이벤트
    const onClickSendSMS = async () => {
        // 빈칸 체크
        if (!form?.siteId) {
            removeCurrentPopup();
            addPopup({
                data: {
                    type: "simple",
                    text: "현장을 선택하세요.",
                },
            });
            return;
        }

        // 재인증 필요 없는 현장일때 - 인증 완료 처리
        if (stepState[1].isDone) {
            setStepState((prev) => {
                return [...prev].map((ele) => {
                    if (ele.step === "2") return { ...ele, isSuccess: true, subText: "인증이 완료되었습니다." };
                    if (ele.step === "3") return { ...ele, isDone: true, isSuccess: true }; // 마지막 단계
                    return ele;
                });
            });
            let selectedSite = siteInfo?.siteList?.find((ele) => ele.siteId === form?.siteId); // 선택된 현장
            // (현장변경 팝업)
            if (!isForLogin) {
                getInfo({ siteInfo: { siteName: selectedSite?.siteName, siteId: selectedSite?.siteId }, viewerInfo: { viewerId: selectedSite?.viewerId, phone: params?.phone } }); // 데이터 전달
            }
        }

        // 재인증이 필요한 현장일때 - 인증번호 전송
        if (!stepState[1].isDone) {
            // 초기화
            setStepState((prev) => {
                return [...prev].map((ele) => {
                    if (ele.step === "2") return { ...ele, isDone: false, isSuccess: false, subText: "" };
                    if (ele.step === "3") return { ...ele, isDone: false, isSuccess: false };
                    return ele;
                });
            });
            // 인증번호 전송
            await mutateSMS(
                { phone: params?.phone, siteId: form?.siteId },
                {
                    onSuccess(data) {
                        setStepState((prev) => {
                            return [...prev].map((ele) => {
                                if (ele.step === "2") return { ...ele, isDone: true, isSuccess: true, subText: "인증번호가 전송되었습니다." };
                                if (ele.step === "3") return { ...ele, show: true };
                                return ele;
                            });
                        });
                        // 타임 아웃 시작
                        setTimerStart(true);
                    },
                    onError(error) {
                        setStepState((prev) => {
                            return [...prev].map((ele) => (ele.step === "2" ? { ...ele, isDone: false, isSuccess: false, subText: "인증번호 전송 실패" } : { ...ele }));
                        });
                    },
                    onSettled() {},
                },
            );

            // TEST: 테스트 코드 (성공)
            // setStepState((prev) => {
            //     return [...prev].map((ele) => {
            //         if (ele.step === "2") return { ...ele, isDone: true, isSuccess: true, subText: "인증번호가 전송되었습니다." };
            //         if (ele.step === "3") return { ...ele, show: true };
            //         return ele;
            //     });
            // });
            // setTimerStart(true);

            // TEST: 테스트 코드 (실패)
            // setStepState((prev) => {
            //     return [...prev].map((ele) => (ele.step === "2" ? { ...ele, isDone: true, isSuccess: false, subText: "인증번호 전송 실패" } : { ...ele }));
            // });
        }
    };

    // 3단계) 인증번호 확인 버튼 이벤트
    const onClickSMSValid = async () => {
        // 빈칸 체크
        if (!form?.code) {
            removeCurrentPopup();
            addPopup({
                data: {
                    type: "simple",
                    text: "인증번호를 입력하세요.",
                },
            });
            return;
        }
        // 인증번호 확인
        await mutateSMSValid(
            { phone: params?.phone, siteId: form?.siteId, code: form?.code },
            {
                onSuccess(data: { isValid?: boolean; viewerId?: string }) {
                    console.log(data);
                    setStepState((prev) => {
                        return [...prev].map((ele) => (ele.step === "3" ? { ...ele, isDone: true, isSuccess: true, subText: "인증이 완료되었습니다." } : { ...ele }));
                    });
                    setTimerStart(false); // 타이머 종료

                    // 데이터 전달
                    if (!isForLogin) {
                        let selectedSite = siteInfo?.siteList?.find((ele) => ele.siteId === form?.siteId); // 선택된 현장
                        getInfo({ siteInfo: { siteName: selectedSite?.siteName, siteId: selectedSite?.siteId }, viewerInfo: { viewerId: data.viewerId, phone: params?.phone } });
                    }
                },
                onError(error) {
                    setStepState((prev) => {
                        return [...prev].map((ele) => (ele.step === "3" ? { ...ele, isDone: true, isSuccess: false, subText: "인증 실패" } : { ...ele }));
                    });
                },
            },
        );
    };

    // 로그인 버튼 클릭
    const onClickLogin = async () => {
        let mustData = omit(form, ["code"]);
        let isReady = Object.values(mustData).every((value) => value);

        if (!isReady || !stepState[2].isSuccess) {
            alert("인증을 완료해주세요.");
            return;
        }

        // 인증 완료 후, 로그인
        let selectedSite = siteInfo.siteList?.find((ele) => ele.siteId === form?.siteId); //선택된 현장(recoil)
        // 현장 정보
        setSiteInfo((prev) => ({
            ...prev,
            siteId: selectedSite.siteId,
            siteName: selectedSite.siteName,
        }));
        // 사용자 정보
        setGlobalInfo({
            viewerId: selectedSite?.viewerId,
            phone: params?.phone,
        });

        window.location.reload(); //토큰, 인증 저장후 리로드 필요
    };

    // 타이머 끝나고 처리
    const afterClearTimer = () => {
        setTimerStart(false); // 타이머 안보이게 처리
        // 인증 완료 안됐을시,
        // 인증번호 전송 버튼 다시 열기
        if (!stepState[2].isSuccess) {
            setStepState((prev) => {
                return [...prev].map((ele) => {
                    if (ele.step === "2") return { ...ele, isDone: false, isSuccess: false, subText: "" };
                    if (ele.step === "3") return { ...ele, isDone: false };
                    return ele;
                });
            });
        }
    };

    return (
        <>
            <LoginStyle.FormWrap>
                {/* 휴대폰 번호 입력 */}
                {stepState[0].show && (
                    <LoginStyle.FormRow>
                        <div className="flex">
                            <Input width={300} height={56} ico={IcoPhone} icoA={IcoPhoneA} placeholder="휴대폰 번호 입력" name="phone" onChange={onChange} disabled={stepState[0].isSuccess} addHypen={true} maxLength={13} />
                            <MyButton width={120} height={56} text="현장 조회" colorType="brown" fontSize={16} onClick={onClickGetSite} disabled={stepState[0].isSuccess} />
                        </div>
                    </LoginStyle.FormRow>
                )}

                {/* 현장 선택 */}
                {stepState[1].show && (
                    <LoginStyle.FormRow>
                        <div className="flex">
                            <Select width={300} height={56} fontSize={16} ico={IcoBuilding} icoA={IcoBuildingA} option={siteOptions} placeholder="현장 선택" name="siteId" onChange={onChange} disabled={timerStart || stepState[2].isSuccess} />

                            <MyButton width={120} height={56} text={stepState[1]?.buttonText} colorType="brown" fontSize={16} onClick={onClickSendSMS} disabled={timerStart || stepState[2].isSuccess} />
                        </div>

                        {/* 하단 알림 문구 - subText 유무 체크 */}
                        <div className="flex ai_center">{stepState[1].subText && <div className={`subText ${!stepState[1].isSuccess && "error"}`}>{stepState[1].subText}</div>}</div>
                    </LoginStyle.FormRow>
                )}

                {/* 인증번호 입력 */}
                {stepState[2].show && (
                    <LoginStyle.FormRow>
                        <div className="flex">
                            <Input width={300} height={56} ico={IcoMail} icoA={IcoMailA} placeholder="인증번호 입력" name="code" type="number" maxLength={6} onInput={maxLengthCheck} onChange={onChange} disabled={stepState[2].isSuccess} />
                            {/* 타이머 체크 */}
                            {timerStart && <Timer afterClearTimer={afterClearTimer} />}
                            <MyButton width={120} height={56} text="인증 확인" colorType="brown" fontSize={16} onClick={onClickSMSValid} disabled={stepState[2].isSuccess || !timerStart} />
                        </div>

                        {stepState[2].isDone && <div className={`subText ${!stepState[2].isSuccess && "error"}`}>{stepState[2].subText}</div>}
                    </LoginStyle.FormRow>
                )}
            </LoginStyle.FormWrap>

            {/* [로그인 화면용] - 로그인 버튼  */}
            {isForLogin && (
                <>
                    <LoginStyle.Divider></LoginStyle.Divider>

                    <LoginStyle.LoginBtn id={"enterKey"} className={`${!stepState[2].isSuccess && "disabled"}`} onClick={stepState[2].isSuccess ? onClickLogin : undefined}>
                        Log In
                    </LoginStyle.LoginBtn>
                </>
            )}

            {/* [현장 변경 팝업용] - 안내 글자 */}
            {!isForLogin && !stepState[1].isSuccess && !stepState[2].show && (
                <LoginStyle.Notice>
                    <span className="ico"></span>
                    <span>변경할 현장을 선택 후 우측 버튼을 누르세요.</span>
                </LoginStyle.Notice>
            )}
        </>
    );
}

export default LoginForm;

/* 타이머 구현 */
export const Timer = ({ afterClearTimer }: { afterClearTimer: () => void }) => {
    const timerId = useRef(null);
    const [time, setTime] = useState(60 * 3); // 남은 시간 (단위: 초)

    // 타이머 값 저장 (인터벌)
    useEffect(() => {
        timerId.current = setInterval(() => {
            setTime((prev) => prev - 1);
        }, 1000);
        return () => clearInterval(timerId.current);
    }, [time]);

    // 타이머 종료시
    useEffect(() => {
        if (time < 0) {
            console.log("time over!");
            clearInterval(timerId.current);

            afterClearTimer(); // 타이머 종료 커스텀 액션
        }
    }, [time]);

    // 초단위 형태 변환
    const getSeconds = (time: number) => {
        const seconds = Number(time % 60); // 남은 초 단위

        if (seconds < 0) {
            return "00"; // 00초
        }
        if (seconds < 10) {
            return "0" + String(seconds); // 0[Num]초
        } else {
            return String(seconds); // [Num]초
        }
    };

    return (
        <div style={{ position: "absolute", left: "53%", height: "56px", display: "flex", alignItems: "center" }}>
            <div style={{ color: "#dc0000" }}>
                <span style={{ fontSize: "1.6rem " }}>{parseInt(String(time / 60))}</span>
                <span style={{ fontSize: "1.6rem " }}> : </span>
                <span style={{ fontSize: "1.6rem " }}>{getSeconds(time)}</span>
            </div>
        </div>
    );
};
