import React, { useEffect } from "react";
import usePopup from "../hook/usePopup";
// style
import { PopupStyle, SimplePopupStyle } from "../style";
import MyButton from "components/atoms/MyButton";
import { StateDataType } from "../_interface";

// 심플 팝업 - Text & 하단 닫기 버튼만 존재
export function SimplePopup(props: { data?: StateDataType }) {
    const { text, children } = props.data;
    const { removeCurrentPopup } = usePopup();

    return (
        <PopupStyle.Dimmed>
            <PopupStyle.Wrap>
                <SimplePopupStyle.Box style={{ padding: "40px 20px 20px 20px" }}>
                    <SimplePopupStyle.Cont>
                        {children && children}
                        {text && <div className="message">{text}</div>}
                        <div className="btnWrap">
                            <MyButton id="enterKey" colorType="brown" text="확인" radius={6} onClick={removeCurrentPopup} />
                        </div>
                    </SimplePopupStyle.Cont>
                </SimplePopupStyle.Box>
            </PopupStyle.Wrap>
        </PopupStyle.Dimmed>
    );
}

// 기본 팝업 - 단순 창 닫기
export function BasicPopup(props: any) {
    const { title = "알림", text = "", src } = props.data;
    const { removeCurrentPopup } = usePopup();

    // console.log('BasicPopup : ', props);
    return (
        <PopupStyle.Dimmed>
            <PopupStyle.Wrap>
                <PopupStyle.Box style={{ padding: "28px 20px 20px 20px" }}>
                    <PopupStyle.Head>
                        <PopupStyle.Title src={src}>{title}</PopupStyle.Title>
                        <PopupStyle.Close type={"button"} onClick={removeCurrentPopup}>
                            <span className={"hidden"}>닫기</span>
                        </PopupStyle.Close>
                    </PopupStyle.Head>
                    <PopupStyle.Cont>
                        <div className="message">{text}</div>

                        <PopupStyle.Dashed>
                            <div></div>
                        </PopupStyle.Dashed>
                        <div className="btnWrap">
                            <MyButton colorType="brown" text="확인" radius={6} onClick={removeCurrentPopup} />
                        </div>
                    </PopupStyle.Cont>
                </PopupStyle.Box>
            </PopupStyle.Wrap>
        </PopupStyle.Dimmed>
    );
}

// 버튼 2개 팝업 - twoBtn (simple)
export function TwoBtnPopup(props: any) {
    const { text = "", leftText = "닫기", rightText, leftFunc, rightFunc } = props?.data || {};
    const { removeCurrentPopup } = usePopup();

    return (
        <PopupStyle.Dimmed>
            <PopupStyle.Wrap>
                <SimplePopupStyle.Box style={{ padding: "28px 20px 20px 20px" }}>
                    <SimplePopupStyle.Cont>
                        {text && <div className="message">{text}</div>}
                        {props?.children && props.children}
                        <div className="btnWrap">
                            <MyButton
                                height={40}
                                colorType="brown"
                                text={leftText}
                                radius={6}
                                onClick={async () => {
                                    if (leftFunc) {
                                        await leftFunc();
                                    }
                                    removeCurrentPopup();
                                }}
                            />
                            {rightText && (
                                <MyButton
                                    height={40}
                                    colorType="black"
                                    radius={6}
                                    text={rightText}
                                    onClick={async () => {
                                        if (rightFunc) {
                                            await rightFunc();
                                        }
                                        removeCurrentPopup();
                                    }}
                                />
                            )}
                        </div>
                    </SimplePopupStyle.Cont>
                </SimplePopupStyle.Box>
            </PopupStyle.Wrap>
        </PopupStyle.Dimmed>
    );
}
