import { useEffect, useCallback } from "react";

const KEY_NAME_ESC = "Escape";
const KEY_EVENT_TYPE = "keyup";

//esc 버튼, handleClose 실행
export function useEscapeKey(handleClose: () => void) {
    const handleEscKey = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === KEY_NAME_ESC) {
                handleClose();
            }
        },
        [handleClose],
    );

    useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        return () => {
            document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
    }, [handleEscKey]);
}
