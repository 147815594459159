// webp 이미지 지원 브라우저 체크
// Dom 요소의 id 값을 받아서 해당 Dom에 클래스명 추가
export function detectWebpSupport(id?: string) {
    const image = new Image();
    // 1px x 1px WebP 이미지
    const webpdata = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=";
    const callback = (event: any) => {
        const result = event?.type === "load" && image.width === 1;
        if (result) {
            document.getElementById(id).classList.add("webp");
        } else {
            document.getElementById(id).classList.add("no-webp");
        }
    };
    image.onerror = callback;
    image.onload = callback;
    image.src = webpdata;
}

// 현재 시간 가져오기
export function getCurrentTime() {
    const TIME_ZONE = 9 * 60 * 60 * 1000; // 9시간
    const d = new Date(); // 현재 시간

    const date = new Date(d.getTime() + TIME_ZONE).toISOString().split("T")[0]; // 2021-08-05
    const date2 = new Intl.DateTimeFormat("ko", { dateStyle: "medium" }).format(new Date()); // 2023.3.20.
    const time = d.toTimeString().split(" ")[0]; // HH:MM:SS

    //  sessionStorage.setItem("time", date2 + " " + time); // 로그인 시간

    return date2 + " " + time;
}

// 정규식 하이픈 붙이기 (3 - 3|4 - 4)
export const autoHyphen1 = (target: HTMLInputElement) => {
    target.value = target.value.replace(/[^0-9]/g, "").replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};

// 정규식 하이픈 붙이기 (3 - 4 - 4)
export const autoHyphen2 = (target: HTMLInputElement) => {
    target.value = target.value
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
        .replace(/(\-{1,2})$/g, "");
};

// 하이픈 구현 (+ 익스 환경) (3 - 3|4 - 4) (정규식X)
export function autoHyphen(target: HTMLInputElement, value: string) {
    if (!value) {
        return "";
    }
    value = value.replace(/[^0-9]/g, ""); // '숫자0~9'가 아닌 것을 모두 찾기.

    let result = [];
    let restNumber = "";

    // 지역번호와 나머지 번호로 나누기
    if (value.startsWith("02")) {
        // 서울 02 지역번호
        result.push(value.substr(0, 2));
        restNumber = value.substring(2);
    } else if (value.startsWith("1")) {
        // 지역 번호가 없는 경우
        // 1xxx-yyyy
        restNumber = value;
    } else {
        // 나머지 3자리 지역번호
        // 0xx-yyyy-zzzz
        result.push(value.substr(0, 3));
        restNumber = value.substring(3);
    }
    if (restNumber.length === 7) {
        // 7자리만 남았을 때는 xxx-yyyy
        result.push(restNumber.substring(0, 3));
        result.push(restNumber.substring(3));
    } else {
        result.push(restNumber.substring(0, 4));
        result.push(restNumber.substring(4));
    }

    target.value = result.filter((val) => val).join("-"); // 해당 input 값 변경
}

// type = number 일 때 maxLength 속성 적용 안됨
// 해결 -> onInput 이벤트 이용해서 value 변경처리
export function maxLengthCheck(e: React.ChangeEvent<HTMLInputElement>) {
    let targetType = e.target?.type;
    let targetMaxLength = e.target?.maxLength;

    if (targetType === "number") {
        if (e.target.value.length > targetMaxLength) {
            e.target.value = e.target.value.slice(0, targetMaxLength);
        }
    }
}
