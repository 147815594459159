import { TagStyle } from "./_style";

function NFCTag({ type }: { type?: string }) {
    const NFCType = [
        {
            type: "0",
            title: "동판 시공전",
            color: "#5d616b",
            borderColor: "#A0A5B1",
        },
        {
            type: "1",
            title: "High",
            color: "#0176f9",
            borderColor: "#8ac1ff",
        },
        {
            type: "2",
            title: "Low",
            color: "#009f20",
            borderColor: "#b6e2bf",
        },
    ];

    if (type) {
        const nfc = NFCType.find((ele) => ele.type === type);
        return (
            <TagStyle.Wrap color={nfc?.color} borderColor={nfc?.borderColor}>
                {nfc?.title}
            </TagStyle.Wrap>
        );
    }

    return null;
}

export default NFCTag;
