import React, { useEffect } from "react";
import { PopupCenterStyle, PopupStyle } from "./_style";
import { PopupProps } from "./_interface";

// 가운데 위치 - 이미지 크게보기 처럼 기본
const PopupCenter = ({ width, height, src, title, close, children }: PopupProps) => {
    console.log(children);
    return (
        <>
            <PopupCenterStyle.Dimmed>
                <PopupCenterStyle.Wrap width={width} height={height}>
                    <PopupCenterStyle.Head>
                        <PopupStyle.Title src={src}>{title}</PopupStyle.Title>
                        <PopupStyle.Close type={"button"} onClick={close}>
                            <span className={"hidden"}>닫기</span>
                        </PopupStyle.Close>
                    </PopupCenterStyle.Head>
                    <PopupCenterStyle.Cont>{children && children}</PopupCenterStyle.Cont>
                </PopupCenterStyle.Wrap>
            </PopupCenterStyle.Dimmed>
        </>
    );
};

export default PopupCenter;
