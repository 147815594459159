import React, { useEffect, useRef, useState } from "react";
import { TableStyle } from "./_style";
import { TableProps } from "./_interface";

const Table = ({ thead, tbody, caption, colgroup, height }: TableProps) => {
    const headRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);
    const bodyTable = useRef<HTMLTableElement>(null);

    //스크롤 영역이 실제 컨텐츠 영역보다 커질때
    // 우측여백 추가
    useEffect(() => {
        const setScroll = () => {
            if (bodyRef.current && headRef.current) {
                if (Number(bodyRef.current.scrollHeight) > Number(bodyRef.current.offsetHeight)) {
                    //   headRef.current.style.width = "calc(100% - 6px)"; //thead
                    //   bodyTable.current.style.paddingRight = "2px"; //tbody
                } else {
                    headRef.current.style.width = "100%"; //thead
                    bodyTable.current.style.paddingRight = "0"; //tbody
                }
            }
        };
        setScroll();
        window.addEventListener("resize", setScroll);
        return () => window.removeEventListener("resize", setScroll);
    }, [bodyRef.current?.scrollHeight]);

    return (
        <TableStyle.Wrap height={height}>
            <TableStyle.Thead ref={headRef}>
                <table>
                    <caption>{caption}</caption>
                    <colgroup>
                        {colgroup?.map((i, index) => {
                            return <col key={index} style={{ width: i }} />;
                        })}
                    </colgroup>
                    <thead>{thead}</thead>
                </table>
            </TableStyle.Thead>
            <TableStyle.Tbody className={"cScroll"} ref={bodyRef}>
                <table ref={bodyTable}>
                    <caption>{caption}</caption>
                    <colgroup>
                        {colgroup?.map((i, index) => {
                            return <col key={index} style={{ width: i }} />;
                        })}
                    </colgroup>
                    <tbody>{tbody}</tbody>
                </table>
            </TableStyle.Tbody>
        </TableStyle.Wrap>
    );
};

export default Table;
