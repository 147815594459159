import React from "react";
import { SearchInputStyle } from "./_style";
import { SearchInputProps } from "./_interface";
import Input from "./Input";
import { maxLengthCheck } from "service/global";

const SearchInput = ({ width, height, type, id, name, component, onChange, marginRight, radius, fontSize, value, placeholder, search, maxLength }: SearchInputProps) => {
    const keyEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
        console.log(e.key);
        if (e.key === "Enter") {
            search();
        }
    };
    return (
        //   <SearchInputStyle.Wrap width={width} height={height} marginRight={marginRight}>
        //       <SearchInputStyle.Input id={id} name={name} type={type ? type : "text"} radius={radius} fontSize={fontSize} component={component} onChange={onChange} value={value} placeholder={placeholder} onKeyDown={keyEvent} />
        //       <SearchInputStyle.SearchBtn onClick={search} id="enterKey"></SearchInputStyle.SearchBtn>
        //       {component ? component : null}
        //   </SearchInputStyle.Wrap>
        <SearchInputStyle.Wrap width={width} height={height}>
            <Input width={width} height={height} marginRight={marginRight} id={id} name={name} type={type ? type : "text"} radius={radius} fontSize={fontSize} onChange={onChange} value={value} placeholder={placeholder} onKeyDown={keyEvent} maxLength={maxLength} onInput={maxLengthCheck} />
            <SearchInputStyle.SearchBtn onClick={search}></SearchInputStyle.SearchBtn>
            {component ? component : null}
        </SearchInputStyle.Wrap>
    );
};

export default SearchInput;
