import React, { ReactElement, ReactNode } from "react";
import { SwiperStyle } from "./_style";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination } from "swiper";

// PopupSwiper
const SwiperForPopup = ({ content, perView, perGroup, initialSlide }: any) => {
    return (
        <SwiperStyle.Wrap>
            <Swiper
                initialSlide={initialSlide} // 시작 슬라이드 번호 0 ~
                slidesPerGroup={perGroup ? perGroup : 1}
                spaceBetween={11}
                slidesPerView={perView ? perView : 1}
                onSlideChange={() => console.log("slide change")}
                //  onSwiper={(swiper) => console.log(swiper)}
                navigation={{
                    enabled: true,
                    nextEl: "#btnSwiperNext",
                    prevEl: "#btnSwiperPrev",
                }}
                pagination={{
                    //CHECK: className 대신 id
                    el: "#swiper-pagination",
                    type: "fraction",
                }}
                modules={[Navigation, Pagination]}
            >
                {content}
            </Swiper>
            <SwiperStyle.Navi>
                <button type={"button"} id={"btnSwiperPrev"} className={"btnSwiperPrev"}></button>
                <button type={"button"} id={"btnSwiperNext"} className={"btnSwiperNext"}></button>
            </SwiperStyle.Navi>
            <SwiperStyle.Footer>
                <div id={"swiper-pagination"}></div>
            </SwiperStyle.Footer>
        </SwiperStyle.Wrap>
    );
};

export default SwiperForPopup;
