import { QueryKey } from "react-query";

export const queryKeys = {
    /* 내 현장 */
    siteByUserId: (userId: string) => ["site", userId] as QueryKey[],

    /* 스마트핀 좌표 조회 */
    pinCoordById: (siteId: string) => ["pin", "coord", siteId] as QueryKey[],

    /* 스마트핀 목록 조회 */
    pinListById: (params: object) => ["pin", "list", params] as QueryKey[],

    /* 스마트핀 상세 조회 */
    pinDetailById: (pinId: string) => ["pin", "detail", pinId] as QueryKey[],

    /* 스마트핀 AR상세이력 조회 */
    pinArByParam: (params: object) => ["pin", "ar", params] as QueryKey[],

    /* 작업자 목록 조회 */
    getViewer: (params: object) => ["viewer", params] as QueryKey[],

    /* 작업자 소속 현장 */
    getSiteByViewer: (phone: string) => ["site", phone] as QueryKey[],

    /* 현장 작업자 인증 이력 조회 */
    getViewerLog: (params: object) => ["viewer", params] as QueryKey[],

    /*  AR 신청 가능 회사 조회 */
    getArClient: (siteId?: string) => ["ar", "client", siteId] as QueryKey[],

    /*  AR 신청 유효기간 조회 */
    getArAccess: (viewerId?: string) => ["ar", "access", viewerId] as QueryKey[],
};
