import styled from "styled-components";
import { ButtonProps, CheckboxProps, InputProps, PaginationStyleProps, SelectProps, TableProps, TabStyleProps } from "./_interface";
import IcoCalendar from "assets/images/common/ico-calendar.svg";
import IcoCheck from "assets/images/common/ico-checkbox.svg";
import IcoCheckA from "assets/images/common/ico-checkbox-active.svg";

import IcoNext from "assets/images/common/ico-pagination-next.svg";
import IcoNextA from "assets/images/common/ico-pagination-next-active.svg";

import IcoSearch from "assets/images/common/ico-search.svg";
import IcoSearchA from "assets/images/common/ico-search-active.svg";
import IcoEye from "assets/images/login/ico-eye.svg";
import IcoEyeA from "assets/images/login/ico-eye-active.svg";
import IcoEyeOpen from "assets/images/login/ico-eye-open.svg";
import IcoEyeOpenA from "assets/images/login/ico-eye-open-active.svg";
import IcoSelected from "assets/images/common/ico-selected.svg";
import IcoDown from "assets/images/common/ico-down.svg";
import IcoUp from "assets/images/common/ico-up.svg";

export const ButtonStyleDefault = {
    Wrap: styled.button<ButtonProps>`
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${(p) => (p.width === "full" ? "100%" : typeof Number(p.width) === "number" ? p.width + "px" : "auto")};
        transition: 0.3s;
        height: ${(p) => (p.height ? p.height + "px" : "40px")};
        background-color: ${(p) => (p.colorType === "black" ? "#000" : "#fff")};
        border: ${(p) => (p.colorType === "black" ? "1px solid #000" : "1px solid #c1c1c1")};
        border-radius: ${(p) => (p.radius ? p.radius + "px" : "12px")};
        box-sizing: border-box;
        &:hover {
            background-color: ${(p) => (p.colorType === "black" ? "#525252" : "#fff")};
            border-color: ${(p) => (p.colorType === "black" ? "#525252" : "#fff")};
        }
    `,
    Text: styled.span<ButtonProps>`
        font-size: ${(p) => (p.fontSize ? p.fontSize + "px" : "16px")};
        transition: 0.3s;
        font-weight: 400;
        color: ${(p) => (p.colorType === "black" ? "#fff" : "#888")};
        margin-left: ${(p) => (p.leftIcon ? "10px" : 0)};
        margin-right: ${(p) => (p.rightIcon ? "10px" : 0)};
    `,
    IconBox: styled.span<ButtonProps>`
        width: ${(p) => (p.iconSize ? p.iconSize + "px" : "24px")};
        height: ${(p) => (p.iconSize ? p.iconSize + "px" : "24px")};
        background-repeat: no-repeat;
        background-position: center;
        transition: 0.3s;
        background-image: ${(p) => (p.leftIcon ? `url(${p.leftIcon})` : p.rightIcon ? `url(${p.rightIcon})` : "")};
    `,
};

export const ButtonStyle = {
    ...ButtonStyleDefault,
    Wrap: styled(ButtonStyleDefault.Wrap)`
        &:hover {
            ${ButtonStyleDefault.IconBox} {
                background-image: ${(p) => (p.leftIconActive ? `url(${p.leftIconActive})` : p.rightIconActive ? `url(${p.rightIconActive})` : "none")};
            }
            ${ButtonStyleDefault.Text} {
                color: ${(p) => (p.colorType === "black" ? "#fff" : "#fff")};
            }
        }
    `,
};

export const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    ${ButtonStyle.Wrap} {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
`;

const CheckboxDefaultStyle = {
    Wrap: styled.div`
        display: flex;
        align-items: center;
        /* position: relative; */
    `,
    Label: styled.label<CheckboxProps>`
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        &:before {
            content: "";
            width: 18px;
            height: 18px;
            background: url(${IcoCheck}) no-repeat center;

            background-position: center;
            background-repeat: no-repeat;

            transition: 0.4s;
            box-sizing: border-box;
            margin-right: ${(p) => (p.text ? "10px" : 0)};
        }
    `,
    Input: styled.input`
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        font-size: 0;
        width: 0;
        height: 0;
    `,
};

export const CheckboxStyle = {
    ...CheckboxDefaultStyle,
    Input: styled(CheckboxDefaultStyle.Input)`
        &:checked + ${CheckboxDefaultStyle.Label} {
            &:before {
                background: url(${IcoCheckA}) no-repeat center;
            }
        }
    `,
};

export const CheckboxGroup = styled.div`
    display: flex;
    ${CheckboxStyle.Wrap} {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
    ${CheckboxStyle.Label} {
        color: #000;
        text-indent: 5px;
    }
`;

export const InputStyle = {
    Wrap: styled.div<InputProps>`
        position: relative;
        display: flex;
        align-items: center;
        width: ${(p) => (p.width === "full" ? "100%" : typeof p.width === "number" ? p.width + "px" : "auto")};
        height: ${(p) => (p.height ? p.height + "px" : "40px")};
        margin-right: ${(p) => (p.marginRight ? p.marginRight + "px" : 0)};

        .rmdp-container {
            width: 100%;
            height: 100% !important;
        }
        .rmdp-input {
            z-index: 1;
            width: 100%;
            height: 100%;
            text-align: start;
            font-size: 14px;
            font-weight: 200;
            letter-spacing: -0.42px;
            border: 1px solid #e5e5ec;
            border-radius: 8px;
            padding: 0 16px;
            background: transparent url(${IcoCalendar}) no-repeat right 16px center;
            flex: ${(p) => (p.component ? 1 : "none")};
            :focus {
                outline: none;
                box-shadow: none;
                border: 2px solid #000000;
            }
        }
        .placeHolder {
            line-height: ${(p) => (p.height ? p.height + "px" : "40px")};
            color: #767676;
            padding: 0 20px;
            position: absolute;
            left: 0;
            cursor: default;
        }
    `,
    Input: styled.input<InputProps>`
        background-color: transparent;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        padding: ${(p) => (p.ico ? "0  0 0 58px" : "0 20px")};
        box-sizing: border-box;
        font-size: ${(p) => (p.fontSize ? p.fontSize + "px" : "1.6rem")};
        border: 1px solid #e5e5ec;
        border-radius: ${(p) => (p.radius ? p.radius + "px" : "12px")};
        flex: ${(p) => (p.component ? 1 : "none")};
        transition: background-color 0.3s;
        background: ${(p) => (p.ico ? `url(${p.ico}) no-repeat 20px` : null)};
        &:focus {
            outline: none;
            border: 2px solid #212121;
            background: ${(p) => (p.icoA ? `url(${p.icoA}) no-repeat 20px center` : null)};
            background-color: #fffde8;
        }
        &:disabled {
            background-color: #f1f1f5;
            &:hover {
                background-color: #f1f1f5;
            }
        }
        &:hover {
        }
        &::placeholder {
        }
    `,
};

// 로그인 화면용
export const LoginInputStyle = {
    Wrap: styled.div<InputProps>`
        display: flex;
        flex-direction: column;
        position: relative;
        width: ${(p) => (p.width ? p.width : "100%")};
        height: ${(p) => (p.height ? p.height : "56px")};
    `,
    Input: styled(InputStyle.Input)<{ ico?: string; icoA?: string }>`
        height: 100%;
        padding: 20px 54px;
        box-sizing: border-box;
        border: 1px solid #d0d0d0;
        background: ${(p) => (p.ico ? `url(${p.ico}) no-repeat 20px center` : null)};
        &:focus {
            outline: none;
            border: 1px solid #1b1b1b;
            background: ${(p) => (p.icoA ? `url(${p.icoA}) no-repeat 20px center` : null)};
            background-color: #fff;
        }
    `,
    ShowBtn: styled.button`
        width: 24px;
        height: 24px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        background: url(${IcoEye}) no-repeat center;
        &.isShow {
            background: url(${IcoEyeOpen}) no-repeat center;
        }
        &.isFocus {
            background: url(${IcoEyeA}) no-repeat center;
            &.isShow {
                background: url(${IcoEyeOpenA}) no-repeat center;
            }
        }
    `,
};

export const SearchInputStyle = {
    ...InputStyle,
    Wrap: styled(InputStyle.Wrap)``,
    Input: styled(InputStyle.Input)`
        padding-left: 20px;
        box-sizing: border-box;
        /* caret-color: #a0a5b1; */
        transition: all 0.3s;
        &:focus {
            outline: none;
            border: 1px solid #1b1b1b;
            background-color: #fffde8;
        }
        &:hover {
            background-color: #fffde8;
        }
        &::placeholder {
            color: #767676;
        }
    `,
    // 검색 버튼
    SearchBtn: styled.button<{ ico?: string; icoA?: string }>`
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background: ${(p) => (p.ico ? `url(${p.ico}) no-repeat center` : `url(${IcoSearch}) no-repeat center`)};
        transition: all 0.3s;
        &:hover {
            background: ${(p) => (p.icoA ? `url(${p.icoA}) no-repeat center` : `url(${IcoSearchA}) no-repeat center`)};
        }
    `,
};

export const TextareaStyle = {
    Wrap: styled(InputStyle.Wrap)``,
    Textarea: styled.textarea`
        width: 100%;
        height: 100%;
        resize: none;
    `,
};

export const SelectStyle = {
    Wrap: styled.div<SelectProps>`
        position: relative;
        width: ${(p) => (p.width === "full" ? "100%" : typeof Number(p.width) === "number" ? p.width + "px" : "auto")};
        margin-right: ${(p) => (p.marginRight ? p.marginRight + "px" : 0)};
        height: ${(p) => (p.height ? p.height + "px" : "auto")};
    `,
    Selected: styled.div<SelectProps>`
        position: relative;
        display: block;
        cursor: pointer;
        height: ${(p) => (p.height ? p.height + "px" : "40px")};
        line-height: ${(p) => (p.height ? p.height + "px" : "40px")};
        border: 1px solid #e6e6e6;
        border-radius: ${(p) => (p.radius ? p.radius + "px" : "16px")};
        box-sizing: border-box;
        background: #fff;
        padding: ${(p) => (p.ico ? "0  20px 0 58px" : "0 20px")};
        background: ${(p) => (p.ico ? `url(${p.ico}) no-repeat 20px` : null)};
        display: flex;
        align-items: center;
        &::after {
            content: "";
            margin-left: auto;
            width: 20px;
            height: 20px;
            background: url(${IcoDown}) no-repeat center;
            /* transform: rotate(180deg); */
        }
        .value {
            font-weight: 200;
            font-size: ${(p) => (p.fontSize ? p.fontSize + "px" : "1.4rem")};
            width: ${(p) => (p.width === "full" ? "calc(100% - 40px)" : typeof Number(p.width) === "number" ? p.width - 40 + "px" : "auto")};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            /* font-size: 1.6rem; */
        }
        /* &:before,
        &:after {
            position: absolute;
            top: 50%;
            content: "";
            width: 10px;
            height: 2px;
            background: #e6e6e6;
        }
        &:before {
            right: 26px;
            transform: rotate(40deg) translateY(-50%);
        }
        &:after {
            right: 18px;
            transform: rotate(-40deg) translateY(-50%);
        } */
        &.active {
            border: 2px solid #000;
            background: ${(p) => (p.icoA ? `url(${p.icoA}) no-repeat 20px` : null)};
            &::after {
                background: url(${IcoUp}) no-repeat center;
            }
            + ul,
            + div {
                display: block;
                z-index: 100;
            }
        }
        &.disabled {
            background-color: #f1f1f5;
            &:hover {
                background-color: #f1f1f5;
                cursor: default;
            }
        }
        .placeholder {
            color: #767676;
            font-size: ${(p) => (p.fontSize ? p.fontSize + "px" : "1.4rem")};
        }
    `,
    ListWrap: styled.div<SelectProps>`
        width: 100%;
        max-height: ${(p) => (p.maxHeight ? p.maxHeight + "px" : "200px")};
        /* padding-right: 7px;
        box-sizing: border-box; */
        position: absolute;
        z-index: 10;
        top: calc(100% + 6px);
        left: 0;
        right: 0;
        border: 1px solid #e6e6e6;
        border-radius: ${(p) => (p.radius ? p.radius + "px" : "16px")};
        background: #fff;
        overflow: hidden;
        overflow-y: auto;
        display: none;
    `,
    List: styled.ul<SelectProps>`
        width: 100%;
        height: 100%;
        overflow-y: auto;
    `,
    Item: styled.li<SelectProps>`
        width: 100%;
        height: 48px;
        line-height: 48px;
        /* font-size: 16px; */
        padding: 0px 20px;
        box-sizing: border-box;
        cursor: pointer;
        color: #767676;
        &:hover {
            color: #000;
            background: #fdf4bd url(${IcoSelected}) no-repeat calc(100% - 20px);
        }
        .value {
            /* font-size: 1.6rem; */
            font-size: ${(p) => (p.fontSize ? p.fontSize + "px" : "1.4rem")};
            width: calc(100% - 20px);
            height: 48px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    `,
    SearchWrap: styled.div`
        position: absolute;
        z-index: 10;
        left: 0;
        top: 100%;
        display: none;
        width: 100%;
        border: 1px solid #6247f7;
        border-top: 0;
        background: #fff;
        > div {
            margin: 0 auto;
        }
    `,
    SearchList: styled.ul`
        max-height: 200px;
        overflow-y: auto;
    `,
    SearchItem: styled.li<SelectProps>`
        height: ${(p) => (p.height ? p.height + "px" : "40px")};
        line-height: ${(p) => (p.height ? p.height + "px" : "40px")};
        font-size: 16px;
        padding: 0 16px;
        cursor: pointer;
        color: #000;
        &:hover {
            background: #ccc;
            color: #000;
        }
    `,
    NoResult: styled.li`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
    `,
};

export const TableStyle = {
    Wrap: styled.div<TableProps>`
        width: 100%;
        height: ${(p) => (p.height ? p.height : "auto")};
    `,
    Thead: styled.div<TableProps>`
        width: 100%;
        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0 12px;
            background-color: #645349;
            border-radius: 12px;
            tr {
                height: 24px;
                th {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 300;
                    border-right: 1px solid rgba(227, 219, 166, 0.25);
                    box-sizing: border-box;
                }
            }
        }
    `,
    Tbody: styled.div`
        /* width: calc(100% - 6px); // 스크롤 생겼을 때 */
        height: calc(100% - 56px); // thead 영역 빼기
        overflow: auto;
        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0 4px;
            tr {
                /* height: 52px; */
                transition: all 0.3s;
                &.bgGray {
                    background-color: #f8f8f8;
                }
                &:hover {
                    background-color: #fdf4bd !important;
                    td {
                        border-color: #ffe576;
                        &:first-child {
                            border-color: #ffe576;
                            // 마크 표시
                            position: relative;
                            &::before {
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translate(0, -50%);
                                width: 4px;
                                height: 24px;
                                border-radius: 0 4px 4px 0;
                                background-color: #645349;
                            }
                        }
                        &:last-child {
                            border-color: #ffe576;
                        }
                    }
                }
                td {
                    /* max-height: 50px; */
                    padding: 16px 0;
                    padding-left: 20px;
                    border-bottom: 1px solid #f4f4f4;
                    border-top: 1px solid #f4f4f4;
                    box-sizing: border-box;
                    position: relative;
                    /* td 사이 경계선 */
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: -1px;
                        transform: translate(0, -50%);
                        height: calc(100% - 32px);
                        width: 1px;
                        background-color: #dedede;
                    }
                    &:first-child {
                        border-left: 1px solid #f4f4f4;
                        border-radius: 8px 0 0 8px;
                    }
                    &:last-child {
                        border-right: 1px solid #f4f4f4;
                        border-radius: 0 8px 8px 0;
                        &::after {
                            display: none;
                        }
                    }
                }
                &.noHover {
                    td {
                        border-color: #f4f4f4 !important;
                        &::before {
                            display: none;
                        }
                    }
                    &.bgGray {
                        &:hover {
                            background-color: #f8f8f8 !important;
                        }
                    }
                    &:hover {
                        background-color: #fff !important;
                    }
                }
                &.active {
                    background-color: #fdf4bd;
                    td {
                        border-color: #ffe576;
                        &:first-child {
                            border-color: #ffe576;
                            // 마크 표시
                            position: relative;
                            &::before {
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translate(0, -50%);
                                width: 4px;
                                height: 24px;
                                border-radius: 0 4px 4px 0;
                                background-color: #645349;
                            }
                        }
                        &:last-child {
                            border-color: #ffe576;
                        }
                    }
                }
            }
        }
    `,
};

// 팝업 내의 테이블
export const TablePopupStyle = {
    ...TableStyle,
    Wrap: styled(TableStyle.Wrap)``,
    Thead: styled(TableStyle.Thead)`
        table {
            border-spacing: 0 12px;
            border-radius: 12px 12px 0 0;

            tr {
                height: 16px;
                th {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
    `,
    Tbody: styled(TableStyle.Tbody)`
        height: calc(100% - 39px); // thead 영역 빼기
        table {
            border-spacing: 0 0;
            tr {
                height: 40px;
                &.bgDarkGray {
                    background-color: #efefef;
                }
                &.noHover {
                    td {
                        border: none;
                        &::before {
                            display: none;
                        }
                    }
                    &.bgDarkGray {
                        &:hover {
                            background-color: #efefef !important;
                        }
                    }
                    &:hover {
                        background-color: #f8f8f8 !important;
                    }
                }
                td {
                    border-radius: 0 !important;
                    padding: 0 !important;
                    text-align: center;
                }
            }
        }
    `,
};

export const TabStyle = {
    Wrap: styled.div``,
    List: styled.ul`
        display: flex;
        align-items: flex-end;
    `,
    Item: styled.li`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 170px;
        height: 60px;
        border-radius: 18px 18px 0 0;
        background-color: #5852f4;
        color: #fff;
        font-family: "NanumSquareRound";
        font-weight: 500;
        &:not(:last-child) {
            margin-right: 5px;
        }
        &.active {
            background-color: #fff;
            color: #5852f4;
            &:after {
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                width: 72px;
                height: 4px;
                content: "";
                background-color: #5852f4;
                border-radius: 0 0 4px 4px;
            }
        }
    `,
    TabCont: styled.div<TabStyleProps>`
        background: #fff;
        border-radius: 0 18px 18px 18px;
        width: 100%;
        height: ${(p) => (p.height ? p.height : "100%")};
    `,
    Inner: styled.div`
        height: calc(100% - 48px);
        padding: 24px 30px;
    `,
};

export const PaginationStyle = {
    Wrap: styled.div`
        margin-top: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    First: styled.button<PaginationStyleProps>`
        width: ${(p) => (p.btnSize ? p.btnSize + "px" : "40px")};
        height: ${(p) => (p.btnSize ? p.btnSize + "px" : "40px")};
        font-size: 12px;
        font-weight: 200;
        color: #a0a5b1;

        border: 1px solid #ebebeb;
    `,
    Prev: styled.button<PaginationStyleProps>`
        width: ${(p) => (p.btnSize ? p.btnSize + "px" : "40px")};
        height: ${(p) => (p.btnSize ? p.btnSize + "px" : "40px")};
        background: url(${IcoNext}) no-repeat center;
        transform: rotate(180deg);

        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-right: 10px;
        transition: all.3s;
        &:hover {
            background: url(${IcoNextA}) no-repeat center;
        }
    `,
    Next: styled.button<PaginationStyleProps>`
        width: ${(p) => (p.btnSize ? p.btnSize + "px" : "40px")};
        height: ${(p) => (p.btnSize ? p.btnSize + "px" : "40px")};
        background: url(${IcoNext}) no-repeat center;

        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-left: 10px;
        transition: all.3s;
        &:hover {
            background: url(${IcoNextA}) no-repeat center;
        }
    `,
    Last: styled.button<PaginationStyleProps>`
        width: ${(p) => (p.btnSize ? p.btnSize + "px" : "40px")};
        height: ${(p) => (p.btnSize ? p.btnSize + "px" : "40px")};
        font-size: 12px;
        font-weight: 200;
        color: #a0a5b1;

        border: 1px solid #ebebeb;
    `,
    NumGroup: styled.div`
        display: flex;
        gap: 8px;
    `,
    Num: styled.button<PaginationStyleProps>`
        min-width: ${(p) => (p.btnSize ? p.btnSize + "px" : "40px")};
        height: ${(p) => (p.btnSize ? p.btnSize + "px" : "40px")};
        line-height: ${(p) => (p.btnSize ? p.btnSize + "px" : "40px")};
        padding: 0 4px;
        font-size: 14px;
        font-weight: 200;
        color: #7e7e7e;
        border: 1px solid #ebebeb;
        box-sizing: border-box;
        &.active {
            border-color: #ffce00;
            background: #ffe500;
            color: #000;
            border-radius: 4px;
        }
    `,
    Ellipsis: styled.span`
        color: #7e7e81;
        font-size: 14px;
        font-weight: 200;
        width: 24px;
        height: 24px;
        text-align: center;
        vertical-align: bottom;
    `,
};

// 파이프태그, nfc 종류 태그 등
export const TagStyle = {
    Wrap: styled.div<{ color?: string; borderColor?: string; backgroundColor?: string }>`
        display: inline-block;
        width: max-content;
        padding: 3px 6px;
        font-size: 1.1rem;
        letter-spacing: -0.33px;
        border-radius: 2px;
        border: 1px solid #000;
        border-color: ${(p) => (p.borderColor ? p.borderColor : "#000")};
        color: ${(p) => (p.color ? p.color : "#000")};
        background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : "#ffffff")};
    `,
};
