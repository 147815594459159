// 작업자 페이지에서 사용하는 API //

import { request } from "./common";
import { APIProps } from "./_interface";

/* 작업자 소속 현장 조회 - phone */
export const getSiteByViewer = async (params: { phone?: string }) => {
    console.log("params", params);
    const { phone } = params;

    const { data } = await request.get(`/viewer/site`, { params: { phone } });

    return data.datas;
};

/* 작업자 SMS 인증번호 요청 - phone, siteId */
export const postSMSCode = async (params: { siteId?: string; phone?: string }) => {
    const { siteId, phone } = params;

    const { data } = await request.post(`/viewer/${siteId}/sms`, null, { params: { phone } });

    return data.datas;
    //  throw new Error("에러에러에러");
};

/* 작업자 SMS 인증번호 확인 - phone, siteId, code */
export const postSMSCodeValid = async (params: { siteId?: string; phone?: string; code?: string }) => {
    const { siteId, phone, code } = params;

    const { data } = await request.post(`/viewer/${siteId}/sms/valid`, null, { params: { phone, code } });

    return data.datas;
};

/* 작업자 AR 신청 가능 회사 조회 */
export const getArClient = async (params: { siteId?: string }) => {
    const { siteId } = params;

    const { data } = await request.get(`/viewer/${siteId}/ar/client`);

    return data.datas;
};

/* 작업자 AR 신청 유효기간 조회 */
export const getArAccess = async (params: { viewerId?: string }) => {
    const { viewerId } = params;

    const { data } = await request.get(`/viewer/${viewerId}/ar/access`);

    return data.datas;
};

/* 작업자 AR 서비스 신청 */
export const postArRequest = async (params: APIProps<{ requestList?: { clientId?: string; startDate?: string; endDate?: string }[] }>) => {
    const { viewerId, body } = params;

    const { data } = await request.post(`/viewer/${viewerId}/ar/request`, body);

    return data.datas;
};
