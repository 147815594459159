import styled from "styled-components";
import { GuideProps, PopupProps } from "./_interface";
import { ButtonStyle, CheckboxStyle } from "components/atoms/_style";

export const ContainerStyle = {
    Contain: styled.div`
        width: 100%;
        height: 100%;
        display: flex;
    `,
    Wrap: styled.div`
        width: calc(100% - 220px);
        flex: 1;
        overflow: auto;
    `,
    Content: styled.div`
        min-width: 1100px;
        height: 100%;
        &.full {
            /* min-width: 1440px; */
        }
    `,
    Inner: styled.div`
        height: 100%;
        padding: 20px 20px 20px 0;
        box-sizing: border-box;
        background: #222221;
    `,
    WhiteBoard: styled.div`
        width: 100%;
        height: 100%;
        border-radius: 3ex;
        padding: 30px;
        box-sizing: border-box;
        background: #fff;
    `,
};

export const PopupStyle = {
    Wrap: styled.div<PopupProps>`
        position: absolute;
        right: 30px;
        top: 100px;
        z-index: 1000;
        width: ${(p) => (p.width ? p.width + "px" : "460px")};
        height: calc(100% - 130px);
    `,
    Head: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 76px;
        padding: 0 30px;
        background: #5852f4;
        border-radius: 38px 38px 0 0;
    `,
    Title: styled.strong<PopupProps>`
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #fff;
        font-size: 20px;
        &:before {
            content: "";
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 10px;
            background-image: ${(p) => (p.src ? `url(${p.src})` : "none")};
            display: ${(p) => (p.src ? "block" : "none")};
        }
    `,
    Close: styled.button`
        position: relative;
        width: 24px;
        height: 24px;
        &:before,
        &:after {
            position: absolute;
            left: 50%;
            top: 50%;
            content: "";
            display: block;
            width: 26px;
            height: 1px;
            background: #fff;
        }
        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    `,
    Cont: styled.div`
        height: calc(100% - 76px);
        border: 1px solid #5852f4;
        border-radius: 0 0 38px 38px;
        background: #fff;
        padding-right: 4px;
        .cScroll {
            height: 100%;
        }
    `,
    Inner: styled.div`
        &:not(:last-child) {
            border-bottom: 2px dashed #babae8;
        }
        padding: 36px 30px;
    `,
};
