import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.scss";

//pages
// import Login from "pages/Login";
import LoginSMS from "pages/LoginSMS";
import { Container } from "./components/templates/_index";
import NotFound from "pages/NotFound";
//recoil
import { useRecoilState } from "recoil";
import { globalState } from "recoil/globalState";

export const mainRoute = "/viewer";

// 로그인 전 라우터
const LoginRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<LoginSMS />} />
            <Route path={mainRoute} element={<LoginSMS />} />
            <Route path="*" element={<NotFound mainRoute={mainRoute} />} />
            {/* <Route path="/changePw" element={<ChangePw />} /> */}
        </Routes>
    );
};

// App 컴포넌트
const App = () => {
    const [globalInfo] = useRecoilState(globalState);
    return (
        <>
            {
                globalInfo.viewerId ? <Container mainRoute={mainRoute} /> : <LoginRoute /> //로그인 컴포넌트
            }
            {/*<Guide/>*/}
        </>
    );
};

export default App;
