import styled from "styled-components";
import { ButtonProps } from "components/atoms/_interface";

// colorType => black, brown, yellow, border-brown
// hover, disabled
// param : width, height, radius, icon, fontSize, colorType, color(폰트색)
const ButtonStyle = {
    Wrap: styled.button<ButtonProps>`
        flex-shrink: 0;
        width: ${(p) => (p.width ? p.width + "px" : "100%")};
        height: ${(p) => (p.height ? p.height + "px" : "36px")};
        border-radius: ${(p) => (p.radius ? p.radius + "px" : "12px")};
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${(p) => (p.colorType === "black" ? "#1b1b1b" : p.colorType === "brown" ? "#645349" : p.colorType === "yellow" ? "#ffe500" : "transparent")};
        border: ${(p) => (p.colorType === "borderBrown" ? "1px solid #645349" : "none")};
        transition: background-color 0.3s;
        &:hover {
            background-color: ${(p) => (p.colorType === "black" ? "#525252" : p.colorType === "brown" ? "#a07d69" : p.colorType === "yellow" ? "#ffce00" : "#eee")};
        }
        &:disabled {
            cursor: default;
            background-color: #f1f1f5;
            border: none;
        }
        &:focus {
            outline: none;
        }
    `,
    Text: styled.span<ButtonProps>`
        font-size: ${(p) => (p.fontSize ? p.fontSize + "px" : "14px")};
        color: ${(p) => (p.color ? p.color : p.colorType === "yellow" ? "#000" : p.colorType === "borderBrown" ? "#645349" : "#fff")};
        font-weight: 300;
        transition: 0.3s;
        margin-left: ${(p) => (p.leftIcon ? "10px" : 0)};
        margin-right: ${(p) => (p.rightIcon ? "10px" : 0)};
        &.disabled {
            color: #999999;
        }
    `,
    IconBox: styled.span<ButtonProps>`
        width: ${(p) => (p.iconSize ? p.iconSize + "px" : "24px")};
        height: ${(p) => (p.iconSize ? p.iconSize + "px" : "24px")};
        background-repeat: no-repeat;
        background-position: center;
        transition: 0.3s;
        background-image: ${(p) => (p.leftIcon ? `url(${p.leftIcon})` : p.rightIcon ? `url(${p.rightIcon})` : "")};
    `,
};

function MyButton({ id, width, height, radius, color, colorType, rightIcon, leftIcon, iconSize, text, onClick, style, fontSize, disabled }: ButtonProps) {
    return (
        <ButtonStyle.Wrap id={id} type={"button"} width={width} height={height} radius={radius} onClick={onClick} colorType={colorType} style={style} disabled={disabled} className="ActionButton">
            {leftIcon && <ButtonStyle.IconBox leftIcon={leftIcon} iconSize={iconSize}></ButtonStyle.IconBox>}
            <ButtonStyle.Text colorType={colorType} color={color} rightIcon={rightIcon} leftIcon={leftIcon} fontSize={fontSize} className={`${disabled && "disabled"} text`}>
                {text}
            </ButtonStyle.Text>
            {rightIcon && <ButtonStyle.IconBox rightIcon={rightIcon} iconSize={iconSize}></ButtonStyle.IconBox>}
        </ButtonStyle.Wrap>
    );
}

export default MyButton;
