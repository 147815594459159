import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { ArAccessProps, DataType } from "pages/_interface";
import { useRecoilValue } from "recoil";
import useQueryState from "hooks/my-react-query/useQueryState";
import { queryKeys } from "hooks/my-react-query/QueryKeys";
import { globalState, siteState } from "recoil/globalState";
import { getArAccess } from "service/api/viewer";

import Table from "components/atoms/Table";
import Pagination from "components/atoms/Pagination";
import PipeTag from "components/atoms/PipeTag";
import StatusTag from "components/atoms/StatusTag";
import EmptyTable from "components/organisms/EmptyTable";

function ApplyList() {
    const globalInfo = useRecoilValue(globalState);
    const siteInfo = useRecoilValue(siteState);
    const siteName = siteInfo?.siteName; // 현재 현장명
    const viewerId = globalInfo?.viewerId; //접속한 작업자 id
    //  const viewerId = "1qTct207Cy0UoUNzDqE3GfT0E000"; //TEST: 테스트
    // [React-Query]
    const { data: arAccessData, refetch } = useQueryState<ArAccessProps[]>(queryKeys.getArAccess(viewerId), () => getArAccess({ viewerId }), {
        enabled: !!viewerId,
    }); // ar 유효기간 조회

    console.log(arAccessData);

    return (
        <>
            {/* 테이블 영역 - pagination 영역, 아래 영역 빼기 */}
            {isEmpty(arAccessData) ? (
                <EmptyTable height="100%" borderNone />
            ) : (
                <Table
                    height={"calc(100% - 22px)"}
                    caption="작업자 관리"
                    colgroup={["30%", "30%", "auto"]}
                    thead={
                        <tr>
                            <th scope={"col"}>현장</th>
                            <th scope={"col"}>관리자</th>
                            <th scope={"col"}>유효 기간</th>
                        </tr>
                    }
                    tbody={
                        <>
                            {arAccessData?.map((ele: ArAccessProps, index) => (
                                <tr className={`noHover ${index % 2 === 1 && "bgGray"}`} key={index}>
                                    {/* 현장 */}
                                    <td>
                                        <div style={{ height: "100%", padding: "3px 0" }}>{siteName}</div>
                                    </td>
                                    {/* 관리자 */}
                                    <td>
                                        <div style={{ maxWidth: "140px", height: "100%", padding: "3px 0" }}>
                                            {/* 관리자명 */}
                                            <p>{ele?.clientName}</p>
                                            {/* 파이프 종류*/}
                                            <div className="flex flwrap" style={{ gap: "4px", marginTop: "12px" }}>
                                                {ele?.clientPipe?.map((ele, idx: number) => (
                                                    <PipeTag type={String(ele?.pipeNumber)} key={String(ele?.pipeNumber) + idx} />
                                                ))}
                                            </div>
                                        </div>
                                    </td>
                                    {/* 유효 기간 */}
                                    <td style={{ paddingRight: "20px" }}>
                                        <div style={{ height: "100%", padding: "3px 0" }}>
                                            {/* 승인 */}
                                            {!isEmpty(ele?.access) && (
                                                <div className="flex ai_center jc_between">
                                                    <span style={{ marginRight: "8px" }}>
                                                        {ele?.access?.startDate} - {ele?.access?.endDate}
                                                    </span>
                                                    <span className="status">
                                                        <StatusTag type="1" />
                                                    </span>
                                                </div>
                                            )}
                                            {/* 대기 */}
                                            {!isEmpty(ele?.standBy) && (
                                                <div className="flex ai_center jc_between" style={{ marginTop: "10px" }}>
                                                    <span style={{ marginRight: "8px" }}>
                                                        {ele?.standBy?.startDate} - {ele?.standBy?.endDate}
                                                    </span>
                                                    <span className="status">
                                                        <StatusTag type="2" />
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </>
                    }
                />
            )}
        </>
    );
}

export default ApplyList;
