import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
    key: "sessionStorage",
    storage: sessionStorage || undefined, // localStorage 또는 sessionStorage
});

interface GlobalStateProps {
    viewerId?: string;
    auth?: string;
    token?: string;
    time?: string;
    phone?: string;
}

export const globalState = atom<GlobalStateProps>({
    key: "globalInfo",
    default: {},
    effects_UNSTABLE: [persistAtom], // persist 적용
});

type SiteProps = {
    siteId?: string;
    siteName?: string;
    address?: string;
    siteList?: { siteName?: string; siteId?: string; isValid?: 0 | 1; viewerId?: string }[];
};

export const siteState = atom<SiteProps>({
    key: "siteInfo",
    default: {},
    effects_UNSTABLE: [persistAtom], // persist 적용
});

export const dateState = atom({
    key: "dateState",
    default: {},
    effects_UNSTABLE: [persistAtom],
});

export const loaderState = atom({
    key: "loaderInfo",
    default: {
        show: false,
    },
});

// * Retrieve the data directly without async await or a Promise.
// 비동기 작업에서 해당 데이터 없으면 재요청하는 코드

// const localStorageEffect = key => ({setSelf, onSet}) => {
//    const savedValue = localStorage.getItem(key)
//    if (savedValue != null) {
//      setSelf(JSON.parse(savedValue));
//    }

//    onSet((newValue, _, isReset) => {
//      isReset
//        ? localStorage.removeItem(key)
//        : localStorage.setItem(key, JSON.stringify(newValue));
//    });
//  };

//  const currentUserIDState = atom({
//    key: 'CurrentUserID',
//    default: 1,
//    effects: [
//      localStorageEffect('current_user'),
//    ]
//  });
