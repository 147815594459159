import React, { useState, useEffect } from "react";

function Loading({ show }: { show?: boolean }) {
    const [imgnNum, setImgNum] = useState("00");

    useEffect(() => {
        let LoadInterval: any = null;
        if (show) {
            let num = 0;
            LoadInterval = setInterval(() => {
                num += 1;
                if (num === 67) {
                    setImgNum("00");
                    num = 0;
                }
                setImgNum(num < 10 ? "0" + String(num) : String(num));
            }, 100);
        } else {
            clearInterval(LoadInterval);
        }
        return () => {
            clearInterval(LoadInterval);
        };
    }, [show]);

    if (show) {
        return (
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    zIndex: 999999,
                    background: "rgba(0,0,0,0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img src={require(`assets/images/loading001/Comp 1_000${imgnNum}.png`)} alt="loading" />
            </div>
        );
    } else {
        return;
    }
}

export default Loading;
