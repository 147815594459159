import React, { useEffect } from "react";
import { TwoBtnPopup } from "popup/popupjs/component/PopupType";
import styled from "styled-components";

const PopupContactStyle = {
    Wrap: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin-bottom: 24px;
    `,
    Title: styled.p`
        font-weight: 400;
        font-size: 1.6rem;
    `,
    Email: styled.p`
        height: 20px;
        color: #645349;
        font-weight: 400;
        font-size: 1.6rem;
        border-bottom: 1px solid #645349;
    `,
};

function PopupContact({ close }: { close: any }) {
    //  const { addPopup, removeCurrentPopup } = usePopup();

    const handleCopyClipBoard = async () => {
        let text = "movements.kr@movements.kr";

        try {
            await navigator.clipboard.writeText(text);
            alert("클립보드에 주소가 복사되었습니다.");
        } catch (e) {
            alert("복사에 실패하였습니다");
        }
    };
    return (
        <>
            {/* usePopup 훅 대신 직접 컴포넌트 재사용 */}
            <TwoBtnPopup key="null" data={{ leftFunc: close, rightFunc: handleCopyClipBoard, rightText: "E-mail 복사" }}>
                <PopupContactStyle.Wrap>
                    <PopupContactStyle.Title>관리자 문의</PopupContactStyle.Title>
                    <PopupContactStyle.Email>movements.kr@movements.kr</PopupContactStyle.Email>
                </PopupContactStyle.Wrap>
            </TwoBtnPopup>
        </>
    );
}

export default PopupContact;
