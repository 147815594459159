import React, { useCallback, useState } from "react";
import { AreaTagStyle } from "./_style";

import { useRecoilState, useRecoilValue } from "recoil";
import { globalState, siteState } from "recoil/globalState";
import PopupTwoBtn from "popup/PopupTwoBtn";

import LoginForm from "pages/LoginSMS/LoginForm";
import usePopup from "popup/popupjs/hook/usePopup";

// (소속) 현장 표시 - 단순 뷰어
function AreaTag() {
    // recoil
    const [globalInfo, setGlobalInfo] = useRecoilState(globalState); // 현재 사이트
    const [siteInfo, setSiteInfo] = useRecoilState(siteState); // 현재 사이트

    const { addPopup, removeCurrentPopup } = usePopup(); //결과 팝업

    const [isOpen, setIsOpen] = useState(false); //현장 변경 팝업
    const [currSite, setCurrSite] = useState<{ siteName?: string; siteId?: string }>({ siteId: "", siteName: "" }); // 현장 정보
    const [currViewer, setCurrViewer] = useState<{ viewerId?: string; phone?: string }>({ viewerId: "", phone: "" }); // 사용자 정보

    // 인증 확인 액션 후 데이터 전달 받기
    const getInfo = useCallback((info: { siteInfo: { siteName?: string; siteId?: string }; viewerInfo: { viewerId?: string; phone?: string } }) => {
        setCurrSite(info?.siteInfo);
        setCurrViewer(info?.viewerInfo);
    }, []);

    // 현장 변경 버튼 클릭 (완료)
    const changeCurrentSite = async () => {
        // 인증완료후, 데이터 받은것 체크하기
        if (currSite?.siteId === "") {
            alert("인증이 완료되지 않았습니다.");
            return;
        }

        // 인증완료후, 데이터 새로 저장 - 로그인
        // 현장 정보
        setSiteInfo((prev) => ({
            ...prev,
            siteId: currSite.siteId,
            siteName: currSite.siteName,
        }));
        // 사용자 정보
        setGlobalInfo({
            viewerId: currViewer?.viewerId,
            phone: currViewer?.phone,
        });

        setIsOpen(false); // 현장변경 팝업 닫기

        // 완료 팝업 오픈
        removeCurrentPopup();
        addPopup({
            key: null,
            data: {
                type: "simple",
                text: "현장 변경 요청이 완료되었습니다.",
            },
        });
        return;
    };

    return (
        <AreaTagStyle.Wrap>
            <div className="title">
                <span className="ico"></span>현장
            </div>
            <div className="content" onClick={() => setIsOpen(true)}>
                {siteInfo?.siteName || "-"}
            </div>

            {/* 현장 변경 팝업 */}
            {isOpen && (
                <PopupTwoBtn title="현장 변경" width={"480px"} height={"336px"} close={() => setIsOpen(false)} leftFunc={changeCurrentSite} leftText="현장 변경">
                    <div style={{ margin: "24px 0" }}>
                        <LoginForm getInfo={getInfo} />
                    </div>
                </PopupTwoBtn>
            )}
        </AreaTagStyle.Wrap>
    );
}

export default AreaTag;
