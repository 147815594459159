import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { PinListStyle } from "./_style";
//recoil
import { useRecoilValue } from "recoil";
import { siteState } from "recoil/globalState";
//hook & service
import useInputArea from "hooks/useInputArea";
import useQueryState from "hooks/my-react-query/useQueryState";
import { queryKeys } from "hooks/my-react-query/QueryKeys";
import { getPinList } from "service/api/common";
import { DataType } from "pages/_interface";
//component
import SearchInput from "components/atoms/SearchInput";
import Pagination from "components/atoms/Pagination";
import TableForPopup from "components/atoms/TableForPopup";
import EmptyTable from "components/organisms/EmptyTable";
import NFCTag from "components/atoms/NFCTag";

// 스마트핀 목록 데이터 -> content:[] 타입
type PinListProps = {
    largeNfc?: number;
    lowNfc?: number;
    smartPinId?: string;
    isSurveyed?: 0 | 1; // 동판 시공 여부
    isCompleted?: 0 | 1; // 시공 완료 여부
};

function PinList({ setSelectedId, smartPinId }: { setSelectedId?: React.Dispatch<any>; smartPinId?: string }) {
    const siteInfo = useRecoilValue(siteState);
    const siteId = siteInfo.siteId; // 현재 현장 아이디

    const [focusData, setFocusData] = useState(null); // 선택된 row 데이터

    const [form, setForm, onChange] = useInputArea({
        smartPinId: "",
    }); // 검색창 form
    const [pageData, setPageData] = useState({
        pageSize: 5,
        pageNo: 1,
    }); // pagination
    const [params, setParams] = useState({
        siteId,
        body: {
            size: 15,
            pageNum: 0,
            smartPinId: "", // 검색
            isCompleted: 1, // 시공 완료 항목만 조회
        },
    }); // 조회 API 파라미터

    //[React-Query]
    const { data: pinListData } = useQueryState<DataType<PinListProps>>(queryKeys.pinListById(params), () => getPinList(params), {
        enabled: !!siteId,
    });

    // pagination - 페이지 변경시
    useEffect(() => {
        const pageNum = Number(pageData.pageNo) - 1;
        setParams((prev) => ({ ...prev, body: { ...prev.body, pageNum } }));
    }, [pageData.pageNo]);

    // 페이지 변경
    const changePage = (pageNo?: number) => {
        setPageData((prev) => ({ ...prev, pageNo: pageNo })); // 1페이지로 -> 상단에 setParam 일어남
    };

    // 검색 버튼 이벤트
    const onClickSearch = () => {
        setParams((prev) => {
            let newParam = { ...prev };
            newParam.body = { ...newParam.body, ...form };
            return newParam;
        });
        changePage(1);
    };

    return (
        <PinListStyle.Wrap>
            <PinListStyle.SearchWrap>
                <p className="totalNum">
                    {"총"}
                    <span>{pinListData?.totalElements || "0"}개</span>
                </p>
                <SearchInput width={228} height={40} radius={8} fontSize={14} placeholder="PIN ID 검색" name="smartPinId" onChange={onChange} search={onClickSearch} type="number" maxLength={8} />
            </PinListStyle.SearchWrap>

            {/* 높이 : 전체 - 검색 영역 */}
            <div style={{ height: "calc(100% - 57px)", padding: "24px 20px 0 20px", boxSizing: "border-box" }}>
                {isEmpty(pinListData?.content) ? (
                    <EmptyTable height={"calc(100% - 60px)"} />
                ) : (
                    <TableForPopup
                        height={"calc(100% - 56px - 37px)"} // - pagination, -밑 간격
                        caption="스마트핀 목록"
                        colgroup={["50%", "auto"]}
                        thead={
                            <tr>
                                <th scope={"col"}>PIN ID</th>
                                <th scope={"col"}>NFC 타입</th>
                            </tr>
                        }
                        tbody={
                            <>
                                {!isEmpty(pinListData?.content) &&
                                    pinListData.content.map((ele, index) => (
                                        <tr className={index % 2 === 1 ? "bgDarkGray" : "bgGray"} key={ele.smartPinId} onClick={() => setSelectedId(ele.smartPinId)}>
                                            <td>
                                                <p>{ele?.smartPinId}</p>
                                            </td>
                                            <td>
                                                {ele?.isSurveyed === 0 && <NFCTag type="0" />}
                                                {ele?.isSurveyed === 1 && (
                                                    <PinListStyle.NFCWrap>
                                                        <PinListStyle.NFCType>
                                                            <NFCTag type="1" />
                                                            {ele?.largeNfc}
                                                        </PinListStyle.NFCType>
                                                        <PinListStyle.NFCType>
                                                            <NFCTag type="2" />
                                                            {ele?.lowNfc}
                                                        </PinListStyle.NFCType>
                                                    </PinListStyle.NFCWrap>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </>
                        }
                    />
                )}

                <Pagination param={pageData} setParam={setPageData} totalPages={pinListData?.totalPages || 0} btnSize={32} />
            </div>
        </PinListStyle.Wrap>
    );
}

export default React.memo(PinList);
