import React from "react";
import { CheckboxStyle } from "./_style";
import { CheckboxProps } from "./_interface";

const Checkbox = ({ id, text, name, type, checked, style, onChange }: CheckboxProps) => {
    return (
        <CheckboxStyle.Wrap style={style}>
            <CheckboxStyle.Input
                id={id}
                name={name}
                type={type === "radio" ? "radio" : "checkbox"}
                onChange={onChange}
                // defaultChecked={checked}
                checked={checked}
            />
            <CheckboxStyle.Label htmlFor={id} type={type} text={text}>
                {text}
            </CheckboxStyle.Label>
        </CheckboxStyle.Wrap>
    );
};

export default Checkbox;
