import styled from "styled-components";
import { TocDep1Props, TocDep2Props } from "./_interface";

import MenuLogo from "assets/images/common/Menu/menu-logo.svg";
import MenuLogoS from "assets/images/common/Menu/menu-logo-small.svg";
import icoArrow from "assets/images/common/Menu/ico-arrow.svg";
import icoArrowH from "assets/images/common/Menu/ico-arrow-hover.svg";
import icoLogout from "assets/images/common/Menu/ico-logout.svg";
import icoLogoutA from "assets/images/common/Menu/ico-logout-active.svg";
import IcoInfo from "assets/images/common/Menu/ico-info.svg";
import IcoInfoA from "assets/images/common/Menu/ico-info-active.svg";
import IcoMail from "assets/images/common/Menu/ico-mail.svg";
import IcoMailA from "assets/images/common/Menu/ico-mail-active.svg";

export const TocStyle = {
    Wrap: styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 260px;
        padding: 50px 30px 40px 30px;
        box-sizing: border-box;
        background: #222221;
        transition: 0.6s;
        &.hide {
            width: 130px;
        }
    `,
    Toggle: styled.button`
        width: 32px;
        height: 32px;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.18);
        position: absolute;
        top: 55px;
        right: -16px;
        bottom: 0;
        color: white;
        z-index: 999;
        transform: rotate(180deg);
        background: #edeef1 url(${icoArrow}) no-repeat center;
        transition: all 0.4s;
        &:hover {
            background: #222221 url(${icoArrowH}) no-repeat center;
        }
        &.hide {
            transform: rotate(0deg);
        }
    `,
    Logo: styled.h1`
        width: 158px;
        height: 45px;
        margin-bottom: 55px;
        background: url(${MenuLogo}) no-repeat center;
        &.hide {
            width: 50px;
            height: 45px;
            background: url(${MenuLogoS}) no-repeat center;
        }
    `,
    List: styled.ul`
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        overflow: hidden;
    `,
    Item: styled.li<TocDep1Props<TocDep2Props>>`
        padding: 0 24px;
        box-sizing: border-box;
        color: #a0a5b1;
        font-size: 1.6rem;
        height: 56px;
        overflow: hidden;
        display: flex;
        align-items: center;
        transition: all 0.4s;
        cursor: pointer;
        .textArea {
            display: flex;
            align-items: center;
            gap: 12px;
            span {
                width: 150px;
            }
            &:before {
                content: "";
                width: 24px;
                height: 24px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url(${(p) => (p.ico ? p.ico : "none")});
            }
        }
        &:not(:last-child) {
            margin-bottom: 16px; // 메뉴 사이 간격
        }
        &:hover {
            color: #fff;
            font-weight: 600;
            border-radius: 20px;
            background-color: #393938;
        }
        // 활성화 메뉴 스타일
        &.active {
            color: #fff;
            font-weight: 600;
            border-radius: 20px;
            background-color: #393938;
            > ul {
                display: block;
            }
            > .textArea {
                &:before {
                    background-image: url(${(p) => (p.icoActive ? p.icoActive : "none")});
                }
            }
        }
        // 축소버전에서 툴팁
        .tooltip {
            width: 0;
            padding: 0;
            opacity: 0;
            color: #fff;
            border-radius: 2px;
            font-size: 1.2rem;
            position: absolute;
            left: 70px;
            z-index: 9999;
            background-color: #1b1b1b;
            transition: all 0.3s ease-in-out;
            &.show {
                width: max-content;
                padding: 5px 8px;
                left: 90px;
                opacity: 1;
            }
        }
    `,
    BottomList: styled.ul`
        width: 100%;
        margin-bottom: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 5px;
    `,
    BottomItem: styled.li<TocDep1Props<TocDep2Props>>`
        height: 48px;
        padding-left: 10px;
        box-sizing: border-box;
        color: #a0a5b1;
        font-size: 1.6rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        gap: 12px;
        transition: all 0.4s;
        cursor: pointer;
        &:before {
            content: "";
            width: 24px;
            height: 24px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(${(p) => (p.ico ? p.ico : "none")});
        }
        &:hover {
            color: #fff;
            &:before {
                background-image: url(${(p) => (p.icoActive ? p.icoActive : "none")});
            }
        }
        // 축소버전
        &.hideV {
            justify-content: center;
            padding-left: 0;
        }
    `,
    Logout: styled.button`
        width: 100%;
        padding-top: 10px;
        border-top: 1px solid #393938;
        display: flex;
        align-items: center;
        transition: all 0.4s;
        .wrap {
            width: 100%;
            height: 56px;
            padding-left: 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            gap: 12px;
            font-weight: 300;
            color: #a0a5b1;
            transition: all 0.3s;
            &.center {
                justify-content: center;
                padding-left: 0;
            }
            span {
                width: 150px;
                text-align: left;
            }
            // 아이콘
            &::before {
                content: "";
                display: inline-block;
                flex-shrink: 0;
                width: 24px;
                height: 24px;
                background: url(${icoLogout}) no-repeat center;
            }
            &:hover {
                color: #fff;
                &::before {
                    background: url(${icoLogoutA}) no-repeat center;
                }
            }
        }
    `,
    Tooltip: styled.div`
        width: 0;
        padding: 0;
        opacity: 1;
        color: #fff;
        border-radius: 2px;
        font-size: 1.2rem;
        position: absolute;
        left: 70px;
        z-index: 9999;
        background-color: #1b1b1b;
        transition: all 0.3s ease-in-out;
        &.show {
            width: max-content;
            padding: 5px 8px;
            left: 90px;
            opacity: 1;
        }
    `,
};

// 지도 - 버튼 등 스타일
export const FunctionStyle = {
    Wrap: styled.div`
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 99;
        display: flex;
        gap: 12px;
    `,
    CheckBoxWrap: styled.div`
        width: fit-content;
        height: auto;
        padding: 0 15px;
        display: flex;
        align-items: center;
        gap: 24px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 12px;
        color: #fff;
    `,
    LegendWrap: styled.ul<{ ico1?: string; ico2?: string }>`
        /* width: 144px; */
        height: 48px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        gap: 20px;
        align-items: center;
        padding: 0 16px;
        box-sizing: border-box;
        li {
            display: flex;
            align-items: center;
            gap: 10px;
            &::before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
            }
            &:nth-child(1) {
                &::before {
                    background: ${(p) => (p.ico1 ? `url(${p.ico1}) no-repeat center` : null)};
                }
            }
            &:nth-child(2) {
                margin-left: auto;
                &::before {
                    background: ${(p) => (p.ico1 ? `url(${p.ico2}) no-repeat center` : null)};
                }
            }
        }
    `,
};

// 노란색 정보 박스 스타일 - RowDetail
export const InfoBoxStyle = {
    Wrap: styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
    `,
    Box: styled.div<{ bgColor?: string }>`
        box-sizing: border-box;
        border: 2px solid #ffe500;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: ${(p) => (p.bgColor ? p.bgColor : "transparent")};
    `,
    Title: styled.div`
        width: calc(100% + 4px); // + border
        height: 53px;
        padding: 0 20px;
        box-sizing: border-box;
        background-color: #fdf4bd;
        border: 2px solid #ffe500;
        border-top: none;
        border-radius: 8px;
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
    `,
    Ul: styled.ul`
        width: 100%;
        padding: 24px 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 20px;
    `,
    Li: styled.li`
        width: 100%;
        height: 19px;
        display: flex;

        align-items: center;
        justify-content: space-between;
        p {
            font-size: 1.6rem;
            font-weight: 400;
        }
    `,
    SubTitle: styled.div`
        font-size: 1.6rem;
        font-weight: 400;
        &:nth-child(1) {
            color: #645349;
            font-weight: 200;
        }
    `,
};
