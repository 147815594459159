/* Smart-Pin 공통 */
import axios from "axios";
import { APIProps, LoginType, PageProps, ResponseType } from "./_interface";

export const request = axios.create({
    baseURL: "/smart-pin/api",
    headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
    },
});

/* 로그인 */
export const loginFetch = async (params: LoginType) => {
    const { data } = await request.post(`/login`, params);

    sessionStorage.setItem("token", data?.datas?.token);

    return data.datas;
};

// Logout시, 삭제
export const removeUserData = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("sessionStorage"); // remove recoil-persist
    sessionStorage.removeItem("language");
};

/* 스마트핀 좌표 조회 - siteId */
export const getPinCoords = async (params: { siteId?: string }) => {
    const { siteId } = params;

    const { data } = await request.get(`/site/${siteId}/pin/coordinate`);

    return data.datas;
};

/* 스마트핀 목록 조회(페이지) - siteId, smartPinId */
export const getPinList = async (params: APIProps<PageProps & { smartPinId?: string }>) => {
    const { siteId, body } = params;

    const { data } = await request.get(`/site/${siteId}/pin/list`, { params: body });

    return data.datas;
};

/* 스마트핀 상세 조회 - siteId, smartPinId */
export const getPinDetail = async (params: { siteId?: string; smartPinId?: string }) => {
    const { siteId, smartPinId } = params;

    const { data } = await request.get(`/site/${siteId}/pin/${smartPinId}`);

    return data.datas;
};

// 예시) 송장 이미지 다운로드 주소
export const getUrlInvoice = (params: { siteId?: string | number; invoiceImgId?: string }) => {
    return `/api/inventory/${params.siteId}/invoice/${params.invoiceImgId}`;
};
