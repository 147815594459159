import React from "react";
import { InputStyle } from "./_style";
import { InputProps, InputType } from "./_interface";
import { autoHyphen } from "service/global";

const Input = ({ width, height, type, id, name, component, onChange, marginRight, value, disabled, ico, icoA, radius, fontSize, placeholder, defaultValue, addHypen, maxLength, onInput, onKeyDown }: InputProps) => {
    const onInputFunc = (e: React.ChangeEvent<HTMLInputElement>) => {
        // 자동 하이픈
        if (addHypen) {
            autoHyphen(e.target, e.target.value);
        }
        // 그 외 onInput 이벤트
        if (onInput) {
            onInput(e);
        }
    };
    return (
        <InputStyle.Wrap width={width} height={height} marginRight={marginRight}>
            <InputStyle.Input
                id={id}
                name={name}
                type={type ? type : "text"}
                component={component}
                onChange={onChange}
                value={value}
                disabled={disabled}
                ico={ico}
                icoA={icoA}
                radius={radius}
                fontSize={fontSize}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onInput={onInputFunc}
                maxLength={maxLength}
                onKeyDown={onKeyDown}
            />
            {component ? component : null}
        </InputStyle.Wrap>
    );
};

export default Input;
