import { TagStyle } from "./_style";

// 파이프 종류 라벨 형태
function PipeTag({ type }: { type?: string }) {
    // 관종류
    const pipeType = [
        {
            type: "1",
            title: "상수",
            color: "#0176f9",
            borderColor: "#8ac1ff",
        },
        {
            type: "2",
            title: "오수",
            color: "#fe00fe",
            borderColor: "#ffa2ff",
        },
        // {
        //    type: "3",
        //    title: "우수",
        //    color: "#fe00fe",
        //    borderColor: "#ffa2ff",
        // },
        // {
        //    type: "4",
        //    title: "재활용",
        //    color: "#fe00fe",
        //    borderColor: "#ffa2ff",
        // },
        {
            type: "5",
            title: "도시가스",
            color: "#ff6600",
            borderColor: "#ffc096",
        },
        {
            type: "6",
            title: "통신",
            color: "#009f20",
            borderColor: "#b6e2bf",
        },
        {
            type: "7",
            title: "전기",
            color: "#24a9ba",
            borderColor: "#24a9ba",
        },
        // {
        //    type: "8",
        //    title: "전기(전력간선)",
        //    color: "#24a9ba",
        //    borderColor: "#24a9ba",
        // },
        // {
        //    type: "9",
        //    title: "전기(전력인입)",
        //    color: "#24a9ba",
        //    borderColor: "#24a9ba",
        // },
        {
            type: "10",
            title: "송유",
            color: "#645349",
            borderColor: "#a48674",
        },
        {
            type: "11",
            title: "난방",
            color: "#f51000",
            borderColor: "#ffc1bc",
        },
        // 새로운 파이프 번호 체계
        {
            type: "1000",
            title: "상수",
            color: "#0176f9",
            borderColor: "#8ac1ff",
        },
        {
            type: "2000",
            title: "하수",
            color: "#fe00fe",
            borderColor: "#ffa2ff",
        },
        {
            type: "3000",
            title: "가스",
            color: "#ff6600",
            borderColor: "#ffc096",
        },
        {
            type: "4000",
            title: "통신",
            color: "#009f20",
            borderColor: "#b6e2bf",
        },
        {
            type: "5000",
            title: "전기",
            color: "#24a9ba",
            borderColor: "#24a9ba",
        },
        {
            type: "6000",
            title: "송유",
            color: "#645349",
            borderColor: "#a48674",
        },
        {
            type: "7000",
            title: "난방",
            color: "#f51000",
            borderColor: "#ffc1bc",
        },
    ];

    if (type) {
        const pipe = pipeType.find((ele) => ele.type === type);
        if (pipe) {
            return (
                <TagStyle.Wrap color={pipe?.color} borderColor={pipe?.borderColor}>
                    {pipe?.title}
                </TagStyle.Wrap>
            );
        }
    }

    return null;
}

export default PipeTag;
